import React, { useState, useEffect } from "react";
import {
  Table,
  Spinner,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaTrash, FaEdit, FaExternalLinkAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  getPapers,
  deletePaper,
  getPaperById,
} from "../../../services/userProfileService";

import SharedPagination from "../../../components/SharedPagination";
import AddPaper from "./AddRadovi";
import "./RadoviList.css";
const RadoviList = () => {
  const [papers, setPapers] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); 

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);

  const [searchQuery, setSearchQuery] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [editingPaper, setEditingPaper] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPaperId, setSelectedPaperId] = useState(null);

  const navigate = useNavigate();

  const fetchPapers = async () => {
    try {
      setLoading(true);
      const data = await getPapers(page, searchQuery);

      setPapers(data.results);
      setCount(data.count);
      if (data.page_size) {
        setPageSize(data.page_size); 
      }
    } catch (error) {
      console.error("Error fetching papers:", error);
      toast.error("Greška pri učitavanju radova.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPapers();
  }, [page, searchQuery]);

  const handleStaffPapers = () => {
    const slug = localStorage.getItem("slug");
    navigate(`/fakultet/nastavno-osoblje/${slug}`, {
      state: { from: "osoblje/radovi" },
    });
  };

  const handleAllPapers = () => {
    navigate("/istrazivanje/osoblje/naucni-radovi");
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setSearchParams({ page: newPage });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchParams({ page: 1 });
  };

  const handleAddPaper = () => {
    setShowForm(true);
    setEditingPaper(null);
  };

  const handleEditPaper = async (id) => {
    try {
      setLoading(true);
      const paperData = await getPaperById(id);
      setEditingPaper(paperData);
      setShowForm(true);
    } catch (error) {
      toast.error("Greška pri učitavanju rada.");
    } finally {
      setLoading(false);
    }
  };

  const handlePaperUpdated = () => {
    fetchPapers();
    setShowForm(false);
    setEditingPaper(null);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedPaperId(id);
    setShowDeleteModal(true);
  };

  const removePaper = async () => {
    if (!selectedPaperId) return;
    try {
      setShowDeleteModal(false);
      setLoading(true);
      await deletePaper(selectedPaperId);
      toast.success("Rad uspješno obrisan!");
      fetchPapers();
    } catch (error) {
      toast.error("Greška pri brisanju rada.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3" style={{ maxHeight: "max-content" }}>
      <div className="mb-3">
        <h2>Naučni radovi</h2>
        {!showForm && (
          <div className="d-flex justify-content-end user-panel-radovi-table-btns">
            <Button onClick={handleStaffPapers} variant="success" className="me-2"
            >
              Pogledaj svoje radove
            </Button>
            <Button onClick={handleAllPapers} variant="info" className="me-2">
              Pogledaj sve radove
            </Button>
            <Button onClick={handleAddPaper} variant="primary">
              Dodaj novi rad
            </Button>
          </div>
        )}
      </div>

      {showForm ? (
        <AddPaper
          onCancel={() => {
            setShowForm(false);
            setEditingPaper(null);
          }}
          existingData={editingPaper}
          onPaperUpdate={handlePaperUpdated}
        />
      ) : (
        <>
          <InputGroup className="mb-3 radovi-search">
            <FormControl
              placeholder="Pretraži radove..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          <div style={{ overflow: "auto" }}>
            <Table bordered hover className="bg-white shadow-sm radovi-table">
              <thead>
                <tr>
                  <th className="paper-th-title">Naslov</th>
                  <th>Datum publikacije</th>
                  <th>URL</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : papers.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Nema dostupnih radova.
                    </td>
                  </tr>
                ) : (
                  papers.map((paper) => (
                    <tr key={paper.id}>
                      <td className="paper-title">{paper.title}</td>
                      <td>{paper.date_published}</td>

                      {/* New "URL" column with an icon link */}
                      <td className="text-center">
                        <a
                          href={paper.paper_url}
                          className="external-link-icon"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <FaExternalLinkAlt />
                        </a>
                      </td>

                      {/* Action buttons */}
                      <td>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="me-2"
                          onClick={() => handleDeleteConfirmation(paper.id)}
                        >
                          <FaTrash />
                        </Button>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleEditPaper(paper.id)}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          {/* Pagination */}
          <SharedPagination
            currentPage={page}
            totalPages={Math.ceil(count / pageSize)} // adjust if needed
            onPageChange={handlePageChange}
          />
        </>
      )}

      {/* Delete modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Body className="text-center radovi-modal">
          <h5>Jeste li sigurni da želite izbrisati ovaj rad?</h5>
        </Modal.Body>
        <Modal.Footer className="radovi-footer">
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Otkaži
          </Button>
          <Button variant="danger" onClick={removePaper}>
            Da, izbriši
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RadoviList;
