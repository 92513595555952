import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaCity,
  FaGlobe,
  FaEnvelope,
  FaPhone,
  FaClock,
} from "react-icons/fa";
import ButtonComponent from "../../components/ButtonComponent";
import { useTranslation } from "react-i18next";
import styles from "./Kontakt.module.css";
import { Link } from "react-router-dom";
import ContactCard from "../../components/KontaktKartica";

const Kontakt = ({ bg }) => {
  const { t } = useTranslation();

  const contactData = [
    {
      title: t("contact.addressTitle"),
      details: [
        {
          icon: <FaMapMarkerAlt className="me-2" />,
          text: "Vilsonovo šetalište 9",
        },
        { icon: <FaCity className="me-2" />, text: "71000 Sarajevo" },
        { icon: <FaGlobe className="me-2" />, text: "Bosna i Hercegovina" },
      ],
    },
    {
      title: t("contact.facultyTitle"),
      details: [
        { icon: <FaEnvelope className="me-2" />, text: "info@mef.unsa.ba" },
        { icon: <FaPhone className="me-2" />, text: "+387 33 729 800" },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.facultyWorkTime"),
        },
      ],
    },
    {
      title: t("contact.studentServiceTitle"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "studentska_sluzba@mef.unsa.ba",
        },
        { icon: <FaPhone className="me-2" />, text: "+387 33 729 813" },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.studentServiceWorkTime"),
        },
      ],
    },
    {
      title: t("contact.libraryTitle"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "biblioteka@mef.unsa.ba",
        },
        { icon: <FaPhone className="me-2" />, text: "+387 33 729 814" },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.libaryWorkTime"),
        },
      ],
    },
  ];

  return (
    <div className={`${bg} container__wrapper`} id="kontakt">
      <Container>
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div className="mb-3">
            <h2 className="section_title">{t("contact.title")}</h2>
            <p className="section_subtitle">{t("contact.subtitle")}</p>
          </div>
          <Link
            to={`/fakultet/kontakt`}
            style={{ textDecoration: "none", color: "inherit" }}
            className="section__buttons"
          >
            <ButtonComponent
              label={t("contact.sendMessage")}
              color="#ffffff"
              backgroundColor="#051435"
              className="button__style"
            />
          </Link>
        </div>

        <Row className="mt-5">
          {contactData.map((data, index) => (
            <Col md={4} sm={12} className="mb-4" key={index}>
              <ContactCard
                title={data.title}
                details={data.details}
                customClass={styles.cardCustom}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Kontakt;
