import React, { useState, useContext } from "react";
import { Nav, Offcanvas, Button } from "react-bootstrap";
import {
  FaBars,
  FaSignOutAlt,
  FaHome,
  FaTimes,
  FaUser,
  FaLock,
  FaChalkboardTeacher,
  FaBook,
  FaBookOpen,
  FaCogs,
  FaIdBadge,
  FaMicroscope,
  FaClipboardList,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./ProfileSidebar.css";
import Logo from "../../../assets/mef_logo_white.png";
import { AuthContext } from "../../../contexts/AuthContext";

const ProfileSidebar = ({ activeTab, setActiveTab, isProfessor }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <Button
        variant="dark"
        className="d-md-none sidebar-toggle"
        onClick={() => setShowSidebar(true)}
      >
        <FaBars size={24} />
      </Button>

      {/* Offcanvas Sidebar for Mobile */}
      <Offcanvas
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        className="sidebar-offcanvas"
      >
        <Offcanvas.Header className="offcanvas-header-custom">
          <div className="sidebar-header d-flex align-items-center">
            <img
              src={Logo}
              width="40"
              height="40"
              className="rounded-circle me-2"
              alt="MEF logo"
            />
            <span className="sidebar-title">MEF USER PORTAL</span>
          </div>
          <FaTimes
            className="text-white close-btn"
            size={24}
            onClick={() => setShowSidebar(false)}
          />
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Nav className="flex-column sidebar-nav">
            <Nav.Link
              className={activeTab === "profile" ? "active" : ""}
              onClick={() => {
                setActiveTab("profile");
                setShowSidebar(false);
              }}
            >
              <FaUser className="me-2" /> Osnovni podaci
            </Nav.Link>

            <Nav.Link
              className={activeTab === "password" ? "active" : ""}
              onClick={() => {
                setActiveTab("password");
                setShowSidebar(false);
              }}
            >
              <FaLock className="me-2" /> Promjena lozinke
            </Nav.Link>

            {isProfessor && (
              <>
                <Nav.Link
                  className={activeTab === "teaching" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("teaching");
                    setShowSidebar(false);
                  }}
                >
                  <FaChalkboardTeacher className="me-2" /> Nastavnički podaci
                </Nav.Link>

                <hr />
                <Nav.Link
                  className={activeTab === "biography" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("biography");
                    setShowSidebar(false);
                  }}
                >
                  <FaIdBadge className="me-2" /> Biografija
                </Nav.Link>

                <Nav.Link
                  className={activeTab === "bookList" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("bookList");
                    setShowSidebar(false);
                  }}
                >
                  <FaBookOpen className="me-2" /> Knjige
                </Nav.Link>
                <Nav.Link
                  className={activeTab === "paperList" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("paperList");
                    setShowSidebar(false);
                  }}
                >
                  <FaMicroscope className="me-2" /> Naučni radovi
                </Nav.Link>
                <Nav.Link
                  className={activeTab === "projectsList" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("projectsList");
                    setShowSidebar(false);
                  }}
                >
                  <FaCogs className="me-2" /> Projekti
                </Nav.Link>

                <hr />
                <Nav.Link
                  className={
                    activeTab === "examResults" ? "active mb-3" : "mb-3"
                  }
                  onClick={() => {
                    setActiveTab("examResults");
                    setShowSidebar(false);
                  }}
                >
                  <FaClipboardList className="me-2" /> Rezultati ispita
                </Nav.Link>
              </>
            )}
          </Nav>

          <div className="sidebar-logout">
            <div onClick={() => navigate("/")}>
              <FaHome className="logout-icon me-2" size={20} />
              <span>Natrag na početnu</span>
            </div>
            <div onClick={handleLogout}>
              <FaSignOutAlt className="logout-icon me-2" size={20} />
              <span>Odjavi se</span>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Sidebar for Detskop */}
      <div className="profile-sidebar sidebar d-none d-md-flex flex-column">
        <div className="sidebar-header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={Logo}
              width="40"
              height="40"
              className="rounded-circle me-2"
              alt="MEF logo"
            />
            <span className="sidebar-title">MEF USER PORTAL</span>
          </div>
          <FaHome
            className="text-white sidebar-home-icon"
            size={22}
            onClick={() => navigate("/")}
          />
        </div>

        <Nav className="flex-column sidebar-nav">
          <Nav.Link
            className={activeTab === "profile" ? "active" : ""}
            onClick={() => setActiveTab("profile")}
          >
            <FaUser className="me-2" /> Osnovni podaci
          </Nav.Link>

          <Nav.Link
            className={activeTab === "password" ? "active" : ""}
            onClick={() => setActiveTab("password")}
          >
            <FaLock className="me-2" /> Promjena lozinke
          </Nav.Link>

          {isProfessor && (
            <>
              <Nav.Link
                className={activeTab === "teaching" ? "active" : ""}
                onClick={() => setActiveTab("teaching")}
              >
                <FaChalkboardTeacher className="me-2" /> Nastavnički podaci
              </Nav.Link>

              <hr />
              <Nav.Link
                className={activeTab === "biography" ? "active" : ""}
                onClick={() => setActiveTab("biography")}
              >
                <FaIdBadge className="me-2" /> Biografija
              </Nav.Link>

              <Nav.Link
                className={activeTab === "bookList" ? "active" : ""}
                onClick={() => setActiveTab("bookList")}
              >
                <FaBookOpen className="me-2" /> Knjige
              </Nav.Link>
              <Nav.Link
                className={activeTab === "paperList" ? "active" : ""}
                onClick={() => setActiveTab("paperList")}
              >
                <FaMicroscope className="me-2" /> Naučni radovi
              </Nav.Link>
              <Nav.Link
                className={activeTab === "projectsList" ? "active" : ""}
                onClick={() => setActiveTab("projectsList")}
              >
                <FaCogs className="me-2" /> Projekti
              </Nav.Link>
              <hr />
              <Nav.Link
                className={activeTab === "examResults" ? "active mb-2" : "mb-2"}
                onClick={() => setActiveTab("examResults")}
              >
                <FaClipboardList className="me-2" /> Rezultati ispita
              </Nav.Link>
            </>
          )}
        </Nav>

        <div className="sidebar-logout mt-3">
          <div onClick={() => navigate("/")}>
            <FaHome className="logout-icon me-2" size={20} />
            <span>Natrag na početnu</span>
          </div>
          <div onClick={handleLogout}>
            <FaSignOutAlt className="logout-icon me-2" size={20} />
            <span>Odjavi se</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSidebar;
