import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";

import { fetchProfesoriRadovi } from "../../../../services/apiService";
import styles from "./RadoviList.module.css";

const RadoviTab = ({ slug }) => {
  const { t } = useTranslation();

  const [radovi, setRadovi] = useState([]);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    setRadovi([]);
    setLimit(10);
    setHasMore(true);
    setError(null);
  }, [slug]);

  useEffect(() => {
    const loadData = async () => {
      if (!hasMore) return;

      setLoading(true);
      setError(null);
      try {
        const data = await fetchProfesoriRadovi(slug, limit);

        const newItems = Array.isArray(data) ? data : data.results || [];

        setRadovi(newItems);

        if (newItems.length < limit) {
          setHasMore(false);
        }
      } catch (err) {
        console.error("Error fetching radovi:", err);
        setError(t("professorDetail.notAvailable"));
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [slug, limit, hasMore, t]);

  useEffect(() => {
    if (!bottomRef.current) return;

    if (!hasMore || error) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting && !loading) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    observer.observe(bottomRef.current);

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef, hasMore, loading, error]);

  if (loading && radovi.length === 0) {
    return (
      <div className="text-center my-4">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  if (radovi.length === 0) {
    return <p>{t("professorDetail.notAvailable")}</p>;
  }

  return (
    <div className={styles.radoviContainer}>
      <Row>
        {radovi.map((paper, idx) => (
          <Col key={idx} xs={12} className="mb-3">
            <div className={styles.paperCard}>
              <div className={styles.cardHeader}>
                <h6 className={styles.paperTitle}>{paper.title}</h6>
                {paper.paper_url && (
                  <a
                    href={paper.paper_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLinkIcon}
                  >
                    <FaExternalLinkAlt />
                  </a>
                )}
              </div>

              {paper.authors && paper.authors.length > 0 && (
                <div className={styles.authorsRow}>
                  {paper.authors.map((author, i) => {
                    const isLast = i === paper.authors.length - 1;
                    const separator = !isLast ? (
                      <span dangerouslySetInnerHTML={{ __html: " &bull; " }} />
                    ) : null;

                    return (
                      <span key={i}>
                        {author.slug ? (
                          <Link to={author.slug} className={styles.authorLink}>
                            {author.name}
                          </Link>
                        ) : (
                          author.name
                        )}
                        {separator}
                      </span>
                    );
                  })}
                </div>
              )}

              <p className={styles.dateTypeLine}>
                {paper.journal_name && `${paper.journal_name} • `}
                {paper.date_published}
                {paper.paper_type && ` • ${paper.paper_type} `}
              </p>

              {paper.doi && paper.doi_url && (
                <p className={`${styles.doiLine}` }>
                  DOI:{" "}
                  <a
                    href={paper.doi_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {paper.doi}
                  </a>
                </p>
              )}
            </div>
          </Col>
        ))}
      </Row>

      {hasMore && !error && (
        <div ref={bottomRef} className={styles.loadingIndicator}>
          {loading && <Spinner animation="border" />}
        </div>
      )}
    </div>
  );
};

export default RadoviTab;
