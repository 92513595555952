import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import ButtonComponent from "../../../components/ButtonComponent";
import { fetchAllKonferencije } from "../../../services/apiService";
import style from "../Novosti/NovostiLista.module.css";
import { useTranslation } from "react-i18next";
import { truncateText } from "../../../helpers/utils";
import SharedPagination from "../../../components/SharedPagination";

const KonferencijeListComponent = () => {
  const [konferencije, setKonferencije] = useState([]);
  const [konferencijeNum, setKonferencijeNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); 
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllKonferencije(currentPage, titleFilter);
        setKonferencijeNum(data.count);
        setKonferencije(data.results);
        if (data.page_size) {
          setPageSize(data.page_size); 
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(konferencijeNum / pageSize);

  return (
    <>
      <div className="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup className={style.inputField}>
              <FormControl
                placeholder={i18n.language === "bs" ? "Pretraži" : "Search"}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            <Row className="gy-4">
              {isLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : konferencije.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <p>{i18n.language === "bs" ? "Nema podataka" : "No data"}</p>
                </div>
              ) : (
                konferencije.slice(0, 6).map((konferencija) => (
                  <Col key={konferencija.slug} xs={12} sm={6} md={4}>
                    <Link
                      to={`/aktuelnosti/konferencije/${konferencija.slug}`}
                      state={{ fromPage: currentPage }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className={style.cardWrapper}>
                        <img
                          src={konferencija.image}
                          alt={konferencija.title}
                          className={style.imageStyle}
                        />
                        <div className={style.newsDate}>
                          {konferencija.date}
                        </div>
                        <div className={style.newsContent}>
                          <h4 className={style.conferenceHeading}>
                            {truncateText(konferencija.title, 50)}
                          </h4>

                          <ButtonComponent
                            label={t("newsSection.readMore")}
                            color="#000"
                            backgroundColor="#9FDAF8"
                          />
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))
              )}
            </Row>
          </div>

          <SharedPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Container>
      </div>
    </>
  );
};

export default KonferencijeListComponent;
