import React, { useState, useEffect } from "react";
import {
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaRegCalendarAlt,
  FaLinkedin,
  FaGoogle,
  FaResearchgate,
  FaOrcid,
  FaInfoCircle,
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../styles/global.css";
import style from "./NastavnoOsobljeList.module.css";
import { useTranslation } from "react-i18next";
import { fetchAllOsoblje } from "../../../services/apiService";
import SharedPagination from "../../../components/SharedPagination";

const NastavnoOsobljeList = () => {
  const [nastavnoOsoblje, setNastavnoOsoblje] = useState([]);
  const [nastavnoOsobljeNum, setNastavnoOsobljeNum] = useState(0);
  const [selectedRank, setSelectedRank] = useState("");
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); 

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;

  const totalPages = Math.ceil(nastavnoOsobljeNum / pageSize);

  // Fetch data asynchronously
  const fetchData = async (page = 1, rank = "") => {
    setIsLoading(true);
    try {
      const data = await fetchAllOsoblje(page, rank, filterName);

      setNastavnoOsobljeNum(data.count);
      setNastavnoOsoblje(data.results);
      if (data.page_size) {
        setPageSize(data.page_size); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, selectedRank, filterName);
  }, [currentPage, selectedRank, filterName]);

  const handleRankChange = (event) => {
    setSelectedRank(event.target.value);
    navigate("?page=1");
  };

  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    window.scrollTo(0, 0);
  };

  const handleTitleFilterChange = (e) => {
    setFilterName(e.target.value);
    navigate("?page=1");
  };

  return (
    <div className="mt-4 bg-white">
      <div className="container py-5">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom pb-3 mb-4">
          <div className="col-12 col-md-4 mb-3">
            <select
              className="form-select"
              style={{ width: "100%" }}
              value={selectedRank}
              onChange={handleRankChange}
            >
              <option value="">{t("nastavnoOsobljeLista.allStaff")}</option>
              <option value="redovni profesor">
                {t("nastavnoOsobljeLista.professors")}
              </option>
              <option value="vanredni profesor">
                {t("nastavnoOsobljeLista.associateProfessors")}
              </option>
              <option value="docent">
                {t("nastavnoOsobljeLista.assistantProfessors")}
              </option>
              <option value="visi asistent">
                {t("nastavnoOsobljeLista.seniorAssistants")}
              </option>
              <option value="asistent">
                {t("nastavnoOsobljeLista.assistants")}
              </option>
            </select>
          </div>

          <div className="col-12 col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={t("nastavnoOsobljeLista.search")}
                onChange={handleTitleFilterChange}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="row">
            {nastavnoOsoblje.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <p>{t("nastavnoOsobljeLista.noData")}</p>
              </div>
            ) : (
              nastavnoOsoblje.map((professor) => (
                <div className="col-md-6 mb-4" key={professor.user.email}>
                  <div className="card p-4">
                    <Link
                      to={`/fakultet/nastavno-osoblje/${professor.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center">
                          <img
                            className="professor-image avatar me-3"
                            src={professor.user.image}
                            alt={`${professor.user.first_name} ${professor.user.last_name}`}
                          />
                          <h5 className="card-title">
                            {professor.short_rank} {professor.user.first_name}{" "}
                            {professor.user.last_name}
                          </h5>
                          <FaInfoCircle className={style.infoIcon} />
                        </div>
                        <hr />
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{t("nastavnoOsobljeLista.email")}</Tooltip>
                          }
                        >
                          <div style={{ display: "inline-block" }}>
                            <FaEnvelope className="me-2" />
                          </div>
                        </OverlayTrigger>
                        {professor.user.email}
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {t("nastavnoOsobljeLista.phone")}
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block" }}>
                              <FaPhone className="me-2" />
                            </div>
                          </OverlayTrigger>
                          {professor.phone}
                        </div>
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {t("nastavnoOsobljeLista.office")}
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block" }}>
                              <FaBuilding className="me-2" />
                            </div>
                          </OverlayTrigger>
                          {professor.office || "N/A"}
                        </div>
                        <hr />
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {t("nastavnoOsobljeLista.consultation")}
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block" }}>
                              <FaRegCalendarAlt className="me-2" />
                            </div>
                          </OverlayTrigger>
                          {professor.consultation || "N/A"}
                        </div>
                        <hr />
                        <div className="d-flex">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>LinkedIn</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.linkedin, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaLinkedin style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Google Scholar</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.google_scholar, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaGoogle style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>ORCID</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.orcid, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaOrcid style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>ResearchGate</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.research_gate, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaResearchgate style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>

                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        <SharedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default NastavnoOsobljeList;
