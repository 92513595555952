import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchProjektiDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "../../Aktuelnosti/Novosti/NovostiDetaljno.module.css";
import { useTranslation } from "react-i18next";
import IzjavePolaznika from "./IzjavePolaznika";
import ContactCard from "./VoditeljProjekta";
import DocumentsSection from "../../../components/DocumentCard";
import { Helmet } from "react-helmet-async";

const ProjektiDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [projektiDetail, setProjektiDetail] = useState(null);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchProjekti = async (slug) => {
      try {
        const data = await fetchProjektiDetail(slug);
        setProjektiDetail(data);

        const updatedPath =
          i18n.language === "en"
            ? `/en/fakultet/projekti/${data.slug}`
            : `/fakultet/projekti/${data.slug}`;
        window.history.replaceState({}, "", updatedPath);
      } catch (error) {
        navigate("/not-found", { replace: true });
        setError("Failed to load project details. Please try again later.");
      }
    };

    fetchProjekti(slug);
  }, [i18n.language, navigate, slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;

  if (!projektiDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  const sections = [];

  // Prepare Navbar items based on available data
  const items = [
    { nameKey: "projekti.content", link: "#sadrzaj" },

    ...(projektiDetail.voditelji && projektiDetail.voditelji.length > 0
      ? [
          {
            nameKey:
              projektiDetail.voditelji.length > 1
                ? "projekti.voditeljiProjekta"
                : "projekti.voditeljProjekta",
            link: "#voditeljProjekta",
          },
        ]
      : []),
    ...(projektiDetail.izjave && projektiDetail.izjave.length > 0
      ? [
          {
            nameKey:
              projektiDetail.izjave.length > 1
                ? "projekti.izjavePolaznika"
                : "projekti.izjavaPolaznika",
            link: "#izjavepolaznika",
          },
        ]
      : []),
    ...(projektiDetail.gallery && projektiDetail.gallery.length > 0
      ? [{ nameKey: "projekti.gallery", link: "#galerija" }]
      : []),
    ...(projektiDetail.documents && projektiDetail.documents.length > 0
      ? [{ nameKey: "projekti.documents", link: "#dokumenti" }]
      : []),
    ...(projektiDetail.gallery && projektiDetail.gallery.length > 0
      ? [{ nameKey: "projekti.gallery", link: "#galerija" }]
      : []),
  ];

  sections.push({
    id: "sadrzaj",
    component: (
      <Container id="sadrzaj">
        <div className={style.newsContent} id="sadrzaj">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(projektiDetail.content, {
                FORBID_ATTR: ["style"],
              }),
            }}
          />
        </div>
      </Container>
    ),
  });

  if (projektiDetail.voditelji?.length > 0) {
    sections.push({
      id: "voditeljProjekta",
      component: <ContactCard voditelji={projektiDetail.voditelji} />,
    });
  }

  if (projektiDetail.izjave?.length > 0) {
    sections.push({
      id: "izjavepolaznika",
      component: <IzjavePolaznika izjave={projektiDetail.izjave} />,
    });
  }

  if (projektiDetail.documents?.length > 0) {
    sections.push({
      id: "dokumenti",
      component: (
        <DocumentsSection
          documents={projektiDetail.documents}
          bgClass={true}
          backgroundColor={sections.length % 2 === 0 ? "bg-light" : "bg-white"}
        />
      ),
    });
  }

  if (projektiDetail.gallery?.length > 0) {
    sections.push({
      id: "galerija",
      component: (
        <Gallery
          images={projektiDetail.gallery}
          backgroundColor={sections.length % 2 === 0 ? "bg-white" : "bg-light"}
        />
      ),
    });
  }

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{projektiDetail.meta_title}</title>
        <meta name="description" content={projektiDetail.meta_description} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={projektiDetail.meta_title} />
        <meta property="og:description" content={t("meta.og.title")} />
        <meta property="og:image" content={projektiDetail.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <HeadingComponent
        bannerRef={bannerRef}
        title={projektiDetail.title}
        date={projektiDetail.date}
        image={projektiDetail.image}
        sectionName={t("projekti.projekti")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }istrazivanje/fakultet/projekti`}
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      {sections.map((section, index) => (
        <div
          key={section.id}
          className={
            section.id === "galerija"
              ? ""
              : `container__wrapper ${
                  index % 2 === 0 ? "bg-white" : "bg-light"
                }`
          }
          id={section.id}
        >
          {section.component}
        </div>
      ))}
    </div>
  );
};

export default ProjektiDetail;
