import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchProfesoriKnjige } from "../../../../services/apiService";

import styles from "./KnjigaList.module.css";

const BooksTab = ({ slug }) => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const data = await fetchProfesoriKnjige(slug);
        setBooks(data);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
      setLoading(false);
    };

    fetchBooks();
  }, [slug]);

  if (loading) {
    return <p>{t("loading")}</p>;
  }

  if (!books.length) {
    return <p>{t("professorDetail.notAvailable")}</p>;
  }

  return (
    <Row className={styles.booksRow}>
      {books.map((book, index) => (
        <Col md={12} key={index} className="mb-3">
          <Card className={styles.bookCard}>
            <Row className={`g-0 ${styles.card_container}`}>
              {/* Image column */}
              <Col xs={3} sm={2} className={styles.imageCol}>
                <Card.Img
                  variant="top"
                  src={book.image}
                  alt={book.title}
                  className={styles.bookImage}
                />
              </Col>

              {/* Book details column */}
              <Col xs={9} sm={10} className={styles.bookDetailsCol}>
                <Card.Body className={styles.bookCardBody}>
                  <Card.Title className={styles.bookTitle}>
                    {book.title}
                  </Card.Title>

                  {/* Authors */}
                  <p className={`mb-1 ${styles.authorName}`}>
                    {book.authors?.map((author, i) => {
                      const isLast = i === book.authors.length - 1;
                      if (author.slug) {
                        return (
                          <span key={i}>
                            <Link
                              to={author.slug}
                              className={styles.authorLink}
                            >
                              {author.name}
                            </Link>
                            {!isLast && (
                              <span
                                dangerouslySetInnerHTML={{ __html: " &bull; " }}
                              />
                            )}
                          </span>
                        );
                      } else {
                        return (
                          <span key={i}>
                            {author.name}
                            {!isLast && (
                              <span
                                dangerouslySetInnerHTML={{ __html: " &bull; " }}
                              />
                            )}
                          </span>
                        );
                      }
                    })}
                  </p>

                  <p className="mb-1 mt-2">
                    {book.publisher} &bull;{" "}
                    <strong>{book.year_published}</strong>
                  </p>

                  <p className="text-muted mb-1">ISBN: {book.isbn}</p>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default BooksTab;
