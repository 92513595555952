import React, { useState, useEffect, useContext } from "react";
import "./NavigacijaStranice.css";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Dropdown,
  Toast,
} from "react-bootstrap";
import { ReactComponent as Example } from "../assets/icons/sing-in.svg";
import { ReactComponent as OnamaIcon } from "../assets/icons/onama.svg";
import { ReactComponent as NastavnoOsobljeIcon } from "../assets/icons/nastavno.svg";
import { ReactComponent as DokumentiIcon } from "../assets/icons/dokumenti.svg";
import { ReactComponent as StudentskaSluzbaIcon } from "../assets/icons/studentska-sluzba.svg";
import { ReactComponent as JavneNabavkeIcon } from "../assets/icons/javnenabavke.svg";
import { ReactComponent as HistorijaIcon } from "../assets/icons/historija.svg";
import { ReactComponent as KontaktIcon } from "../assets/icons/kontakt.svg";
import { ReactComponent as KnjigeIcon } from "../assets/icons/books.svg";
import { ReactComponent as ProjektiOsobljeIcon } from "../assets/icons/projects-teacher.svg";
import { ReactComponent as NaucniRadIcon } from "../assets/icons/academic-work.svg";

import { ReactComponent as ObavijestiIcon } from "../assets/icons/obavijest.svg";
import { ReactComponent as NovostiIcon } from "../assets/icons/novosti.svg";
import { ReactComponent as SeminariIcon } from "../assets/icons/seminari.svg";
import { ReactComponent as KoneferencijeIcon } from "../assets/icons/konferencija.svg";
import { ReactComponent as MasinijadaIcon } from "../assets/icons/masinijada.svg";
import { ReactComponent as FondoviIcon } from "../assets/icons/fondovi.svg";

import { ReactComponent as RezultatiiIcon } from "../assets/icons/rezultati.svg";
import { ReactComponent as VSMFMasinacIcon } from "../assets/icons/asocijacija.svg";
import { ReactComponent as StudentskiBlogIcon } from "../assets/icons/studentski-blog.svg";
import { ReactComponent as BibliotekaIcon } from "../assets/icons/biblioteka.svg";

import { ReactComponent as PrviCiklusIcon } from "../assets/icons/prvi-ciklus.svg";
import { ReactComponent as DrugiCiklusIcon } from "../assets/icons/drugi-ciklus.svg";
import { ReactComponent as TreciCiklusIcon } from "../assets/icons/treci-ciklus.svg";

import { ReactComponent as KatedraFizikaMatematikaIcon } from "../assets/icons/matematika.svg";
import { ReactComponent as KatedraMehanikaIcon } from "../assets/icons/icon-mehanika-bijela.svg";
import { ReactComponent as OdsjekMasinskeKonstrukcijeIcon } from "../assets/icons/icon-konstrukcije-bijela.svg";
import { ReactComponent as OdsjekMasinskiProizvodniInzinjeringIcon } from "../assets/icons/icon-proizvodni-bijela.svg";
import { ReactComponent as OdsjekIndustrijskoInzinjerstvoMenadzmentIcon } from "../assets/icons/menadzment.svg";
import { ReactComponent as OdsjekEnergetikaProcesnatehnikaOkolinskoInzinjerstvoIcon } from "../assets/icons/energetika.svg";
import { ReactComponent as OdsjekTehnologijaDrvetaIcon } from "../assets/icons/drvo.svg";
import { ReactComponent as OdsjekMotoriVozilaIcon } from "../assets/icons/icon-motori-bijela_4.svg";
import { ReactComponent as OdsjekOdbrambenatehnologijaIcon } from "../assets/icons/odbrambene.svg";

import { ReactComponent as CoursewareIcon } from "../assets/icons/coursewear.svg";

import { ReactComponent as OprojektimaIcon } from "../assets/icons/projekt.svg";
import { ReactComponent as ProjektiRECDIIcon } from "../assets/icons/state.svg";

import { ReactComponent as EUNSAIcon } from "../assets/icons/unsa.svg";
import { ReactComponent as EBibliotekaIcon } from "../assets/icons/ebiblioteka.svg";
import { ReactComponent as WebEmailIcon } from "../assets/icons/mail.svg";

import LanguageSwitcher from "./LanguageSwitcher";

import LoginModal from "./LoginPage/LoginModal";

import { ReactComponent as LinkedIncon } from "../assets/icons/linkedin.svg";

import { AuthContext } from "../contexts/AuthContext";

import { useTranslation } from "react-i18next";

import Logo from "../assets/mef_logo_white.png";
import { useLocation } from "react-router-dom";
import "./NavigacijaStranice.css";

const MyNavbar = () => {
  const [isFakultetDropdownOpen, setIsFakultetDropdownOpen] = useState(false);
  const [isAktuelnostiDropdownOpen, setIsAktuelnostiDropdownOpen] =
    useState(false);
  const [isStudentiDropdownOpen, setIsStudentiDropdownOpen] = useState(false);
  const [isNastavaDropdownOpen, setIsNastavaDropdownOpen] = useState(false);
  const [isProjektiDropdownOpen, setIsProjektiDropdownOpen] = useState(false);
  const [isPortaliDropdownOpen, setIsPortaliDropdownOpen] = useState(false);

  const [showToast, setShowToast] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { isAuthenticated, user, logout, userInitials, profileImage } =
    useContext(AuthContext);

  const isModerator = localStorage.getItem("isModerator") === "true";

  const { t, i18n } = useTranslation();

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang); // This updates the language and saves it in localStorage
  };

  // // Delay timers for each dropdown
  // const [homeTimeout, setHomeTimeout] = useState(null);
  // const [aboutTimeout, setAboutTimeout] = useState(null);
  // const [servicesTimeout, setServicesTimeout] = useState(null);

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isPocetna = location.pathname === "/" || location.pathname === "/en/";

  const [isScrolled, setIsScrolled] = useState(false);
  const [language, setLanguage] = useState("bs");

  if (
    localStorage.getItem("language") === null &&
    !location.pathname.includes("/en")
  ) {
    localStorage.setItem("language", "bs");
    setLanguage("bs");
  }
  if (
    localStorage.getItem("language") === null &&
    location.pathname.includes("/en")
  ) {
    localStorage.setItem("language", "en");
    setLanguage("en");
  }

  const mobileView = window.innerWidth < 1200;

  const handleFakultetMouseEnter = () => {
    setIsFakultetDropdownOpen(true);
  };

  const handleFakultetMouseLeave = () => {
    setIsFakultetDropdownOpen(false);
  };

  const handleAktuelnostiMouseEnter = () => {
    setIsAktuelnostiDropdownOpen(true);
  };

  const handleAktuelnostiMouseLeave = () => {
    setIsAktuelnostiDropdownOpen(false);
  };

  const handleNastavaMouseEnter = () => {
    setIsNastavaDropdownOpen(true);
  };

  const handleNastavaMouseLeave = () => {
    setIsNastavaDropdownOpen(false);
  };

  const handleProjektiMouseEnter = () => {
    setIsProjektiDropdownOpen(true);
  };

  // Handle mouse leave for About dropdown with delay
  const handleProjektiMouseLeave = () => {
    setIsProjektiDropdownOpen(false);
  };

  const handleStudentiMouseEnter = () => {
    setIsStudentiDropdownOpen(true);
  };

  const handleStudentiMouseLeave = () => {
    setIsStudentiDropdownOpen(false);
  };

  const handlePortaliMouseEnter = () => {
    setIsPortaliDropdownOpen(true);
  };

  const handlePortaliMouseLeave = () => {
    setIsPortaliDropdownOpen(false);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "bs";
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  // const toggleLanguage = () => {
  //   const newLanguage = language === "bs" ? "en" : "bs";
  //   setLanguage(newLanguage);
  //   localStorage.setItem("language", newLanguage);
  //   window.location.reload();
  // };

  const value = isScrolled || !isPocetna;

  const toggleNavbar = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  // Function to navigate to profile page
  const navigateToProfile = () => {
    navigate("/profile"); // Adjust with your actual profile route
  };

  // Function to navigate to settings page
  const navigateToSettings = () => {
    navigate("/settings"); // Adjust with your actual settings route
  };

  const handleLogout = () => {
    logout();
    setShowToast(true);
  };

  return (
    <>
      <Navbar
        expand="xl"
        style={{
          backgroundColor: isNavbarExpanded
            ? "#051435"
            : value
            ? "#051435"
            : "transparent",
          backdropFilter: isNavbarExpanded ? undefined : "blur(32px)",
        }}
        variant="dark"
        fixed="top"
        expanded={isNavbarExpanded}
        className="homepage__navbar"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img
              src={Logo}
              width="50"
              height="50"
              className="d-inline-block align-top rounded-circle"
              alt="MEF logo"
            />
            <div className="ms-2">
              <p className="navbar__title">{t("navbar.nazivUniverziteta")}</p>
              <p className="navbar__title">{t("navbar.nazivFakulteta")}</p>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={mobileView ? toggleNavbar : null}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Centered navigation links */}
            <Nav className="mx-auto">
              {/* Home Dropdown */}
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link"
                onClick={mobileView ? toggleNavbar : null}
              >
                {t("navbar.pocetna")}
              </Nav.Link>

              {/* About Dropdown */}
              <div
                className="dropdown-wrapper about__page"
                onMouseEnter={handleFakultetMouseEnter}
                onMouseLeave={handleFakultetMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.fakultet")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="fakultet-dropdown"
                  show={isFakultetDropdownOpen}
                  onClick={() =>
                    setIsFakultetDropdownOpen(!isFakultetDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <OnamaIcon />{" "}
                    </span>
                    {t("navbar.oNama")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/nastavno-osoblje"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <NastavnoOsobljeIcon />{" "}
                    </span>
                    {t("navbar.nastavnoOsoblje")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/dokumenti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <DokumentiIcon />
                    </span>
                    {t("navbar.dokumenti")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/studentska-sluzba"
                    onClick={mobileView ? toggleNavbar : null}
                    // disabled={true}
                  >
                    <span className="svg__wrapper">
                      <StudentskaSluzbaIcon />{" "}
                    </span>
                    {t("navbar.studentskaSluzba")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/biblioteka"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <BibliotekaIcon />
                    </span>
                    {t("navbar.biblioteka")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/fondovi"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <FondoviIcon />
                    </span>
                    {t("navbar.fondovi")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/historija"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <HistorijaIcon />
                    </span>
                    {t("navbar.historija")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/javne-nabavke"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <JavneNabavkeIcon />
                    </span>
                    {t("navbar.javneNabavke")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <KontaktIcon />
                    </span>
                    {t("navbar.kontakt")}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Aktuelnosti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleAktuelnostiMouseEnter}
                onMouseLeave={handleAktuelnostiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.aktuelnosti")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="aktuelnosti-dropdown"
                  show={isAktuelnostiDropdownOpen}
                  onClick={() =>
                    setIsAktuelnostiDropdownOpen(!isAktuelnostiDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/novosti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <NovostiIcon />
                    </span>
                    {t("navbar.novosti")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/obavijesti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <ObavijestiIcon />
                    </span>
                    {t("navbar.obavijesti")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/konferencije"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <KoneferencijeIcon />
                    </span>
                    {t("navbar.konferencije")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/masinijade"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <MasinijadaIcon />
                    </span>
                    {t("navbar.masinijada")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/studenti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <StudentskiBlogIcon />
                    </span>{" "}
                    {t("navbar.studentskiBlog")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/coomingsoon"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <SeminariIcon />
                    </span>
                    {t("navbar.seminari")}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Nastava Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleNastavaMouseEnter}
                onMouseLeave={handleNastavaMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.nastava")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="nastava-dropdown"
                  show={isNastavaDropdownOpen}
                  onClick={() =>
                    setIsNastavaDropdownOpen(!isNastavaDropdownOpen)
                  }
                >
                  <p className="navbar__subsection_title">
                    {t("navbar.ciklusi")}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={"/nastava/prvi-ciklus-studija"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <PrviCiklusIcon />
                      </span>
                      {t("navbar.prviCiklus")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/nastava/drugi-ciklus-studija"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <DrugiCiklusIcon />
                      </span>
                      {t("navbar.drugiCiklus")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/nastava/treci-ciklus-studija"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <TreciCiklusIcon />
                      </span>
                      {t("navbar.treciCiklus")}
                    </NavDropdown.Item>
                  </div>

                  <p className="navbar__subsection_title">
                    {t("navbar.odsjeci")}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/katedra-za-matematiku-i-fiziku"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <KatedraFizikaMatematikaIcon />
                      </span>
                      {t("navbar.fizikaMatematika")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/katedra-za-mehaniku"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <KatedraMehanikaIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {t("navbar.mehanika")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        "/odsjeci/odsjek-za-energetiku-procesnu-tehniku-i-okolinsko-inzinjerstvo"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekEnergetikaProcesnatehnikaOkolinskoInzinjerstvoIcon />
                      </span>
                      <p style={{ margin: "0" }}>
                        {" "}
                        {t("navbar.energetikaOkolinsko")}
                      </p>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        "/odsjeci/odsjek-za-industrijsko-inzinjerstvo-i-menadzment"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekIndustrijskoInzinjerstvoMenadzmentIcon />
                      </span>
                      {t("navbar.industrijskoMenadzment")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/odsjek-za-masinske-konstrukcije"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMasinskeKonstrukcijeIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {t("navbar.masinskeKonstrukcije")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/odsjek-za-masinski-proizvodni-inzinjering"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMasinskiProizvodniInzinjeringIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {t("navbar.masinskiProizvodni")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/odsjek-za-motore-i-vozila"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMotoriVozilaIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {t("navbar.motoriVozila")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/odsjek-za-odbrambene-tehnologije"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekOdbrambenatehnologijaIcon />
                      </span>
                      {t("navbar.odbrambeneTehnologije")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/odsjeci/odsjek-za-tehnologiju-drveta"}
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekTehnologijaDrvetaIcon />
                      </span>
                      {t("navbar.tehnologijaDrveta")}
                    </NavDropdown.Item>
                  </div>
                  <p className="navbar__subsection_title">
                    {t("navbar.ostalo")}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/fakultet/nastavno-osoblje"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <NastavnoOsobljeIcon />
                      </span>
                      {t("navbar.nastavnoOsoblje")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/nastava/rezultati-ispita"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <RezultatiiIcon />
                      </span>
                      {t("navbar.rezultatiIspita")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as="a"
                      href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                      target="_blank" // Opens link in a new tab
                      rel="noopener noreferrer" // Improves security
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <CoursewareIcon />
                      </span>
                      {t("navbar.courseware")}
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
              </div>

              {/* Studenti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleStudentiMouseEnter}
                onMouseLeave={handleStudentiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.studenti")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                    </span>
                  }
                  id="studenti-dropdown"
                  show={isStudentiDropdownOpen}
                  onClick={() =>
                    setIsStudentiDropdownOpen(!isStudentiDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/studenti/vsmf-masinac"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <VSMFMasinacIcon />
                    </span>
                    {t("navbar.asocijacija")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/studenti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <StudentskiBlogIcon />
                    </span>{" "}
                    {t("navbar.studentskiBlog")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/masinijade"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <MasinijadaIcon />
                    </span>
                    {t("navbar.masinijada")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/nastava/rezultati-ispita"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <RezultatiiIcon />
                    </span>
                    {t("navbar.rezultatiIspita")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/biblioteka"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <BibliotekaIcon />
                    </span>
                    {t("navbar.biblioteka")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/fondovi"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <FondoviIcon />
                    </span>
                    {t("navbar.fondovi")}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Projekti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleProjektiMouseEnter}
                onMouseLeave={handleProjektiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.istrazivanje")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="projekti-dropdown"
                  show={isProjektiDropdownOpen}
                  onClick={() =>
                    setIsProjektiDropdownOpen(!isProjektiDropdownOpen)
                  }
                >
                  {/* OVO PROVJERITI DA LI VALJA OVAKO */}
                  <p className="navbar__subsection_title">
                    {t("navbar.publikacije")}
                  </p>

                  <div className="project_wrapper">
                    <NavDropdown.Item
                      as={Link}
                      to="/istrazivanje/osoblje/knjige"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <KnjigeIcon />
                      </span>
                      {t("navbar.knjige")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/istrazivanje/osoblje/naucni-radovi"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <NaucniRadIcon />
                      </span>
                      {t("navbar.naucniRadovi")}
                    </NavDropdown.Item>
                  </div>

                  <p className="navbar__subsection_title">
                    {t("navbar.projekti")}
                  </p>

                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/istrazivanje/fakultet/projekti"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OprojektimaIcon />
                      </span>
                      {t("navbar.projektiFakulteta")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to="/istrazivanje/osoblje/projekti"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <ProjektiOsobljeIcon />
                      </span>
                      {t("navbar.projektiOsoblja")}
                    </NavDropdown.Item>
                  </div>

                  <p className="navbar__subsection_title">
                    {t("navbar.realizovaniProjekti")}
                  </p>

                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/istrazivanje/fakultet/projekti/RECDI"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <ProjektiRECDIIcon />
                      </span>
                      {t("navbar.projektRECDI")}
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
              </div>

              {/* Linkovi Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handlePortaliMouseEnter}
                onMouseLeave={handlePortaliMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {t("navbar.linkovi")}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="portali-dropdown"
                  show={isPortaliDropdownOpen}
                  onClick={() =>
                    setIsPortaliDropdownOpen(!isPortaliDropdownOpen)
                  }
                  disabled={false}
                >
                  <NavDropdown.Item
                    as="a"
                    href="https://www.eunsa.ba/"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <EUNSAIcon />
                    </span>
                    {t("navbar.eUNSA")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://biblioteka.mef.unsa.ba/default"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <EBibliotekaIcon />
                    </span>
                    {t("navbar.eBiblioteka")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://webmail.mef.unsa.ba/"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <WebEmailIcon />
                    </span>
                    {t("navbar.webMail")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <CoursewareIcon />
                    </span>
                    {t("navbar.courseware")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://www.linkedin.com/school/mef-unsa/posts/?feedView=all"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <LinkedIncon />
                    </span>
                    LinkedIn
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>

            {/* Icons on the right */}
            <Nav className="nav__icons">
              {/* Icons Wrapper */}
              {!isAuthenticated ? (
                <div
                  className="d-flex align-items-center style__icon"
                  onClick={handleShowModal}
                >
                  <Example width="24" height="24" />
                </div>
              ) : (
                // Bootstrap Dropdown for user options
                <Dropdown
                  className="user_dropdown"
                  style={{
                    marginRight: "10px",
                    width: "48px",
                  }}
                >
                  <Dropdown.Toggle
                    id="dropdown-custom-components"
                    className="style__icon user_background"
                  >
                    <img
                      src={localStorage.getItem("user_image") || userInitials}
                      alt="user_image"
                      className="user_image"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu user_menu">
                    <Dropdown.Item
                      onClick={navigateToProfile}
                      style={{ margin: "5px" }}
                    >
                      {t("navbar.profile")}
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={navigateToSettings}
                      disabled={true}
                      style={{ margin: "5px" }}
                    >
                      {t("navbar.settings")}
                    </Dropdown.Item> */}
                    {/* Conditionally render admin-specific items */}
                    {isModerator && (
                      <>
                        <Dropdown.Item
                          onClick={() => (window.location.href = "/swagger/")}
                          style={{ margin: "5px" }}
                        >
                          <span className="swagger__link">Swagger</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => (window.location.href = "/admin/")}
                          style={{ margin: "5px" }}
                        >
                          Admin
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{ margin: "5px" }}
                    >
                      {t("navbar.logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* Language Selector Circle */}
              <LanguageSwitcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LoginModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default MyNavbar;
