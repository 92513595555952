import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/ButtonComponent";

import styles from "../../Pocetna/Vijesti.module.css";
import { useTranslation } from "react-i18next";

const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null}
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &larr;
  </div>
);

const ProjektiCarousel = ({ projekti }) => {
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false);
  const { t } = useTranslation();

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1;
    const totalSlides = projekti.length;

    setIsPrevArrowVisible(current > 0);
    setIsNextArrowVisible(current + slidesToShow < totalSlides);
  };

  const sliderSettings = (isDesktop) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  });

  return (
    <div className="container">
      <Slider {...sliderSettings(window.innerWidth > 768)}>
        {projekti.map((news, index) => (
          <div key={index} style={{ padding: "0 30px" }}>
            <Link
              to={`/istrazivanje/fakultet/projekti/${news.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className={styles.cardContainer} style={{ height: "300px" }}>
                <img
                  src={news.image}
                  alt={news.title}
                  className={styles.cardImage}
                  style={{ height: "150px" }}
                />

                <div className={styles.cardContent} style={{ height: "50%" }}>
                  <h5 className={styles.cardTitle}>
                    {news.title.length > 90
                      ? news.title.substring(0, 200) + "..."
                      : news.title}
                  </h5>

                  <ButtonComponent
                    label={t("projekti.learnMore")}
                    color="#000"
                    backgroundColor="#9FDAF8"
                  />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjektiCarousel;
