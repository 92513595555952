import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";
import { fetchNaucniRadovi } from "../../../services/apiService";
import styles from "./NaucniRadovi.module.css";
import ButtonComponent from "../../../components/ButtonComponent";

const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null}
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null}
  >
    &larr;
  </div>
);

const NaucniRadoviSlider = () => {
  const { t } = useTranslation();
  const [radovi, setRadovi] = useState([]);
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false);

  useEffect(() => {
    const loadRadovi = async () => {
      try {
        const data = await fetchNaucniRadovi();
        setRadovi(data);
      } catch (error) {
        console.error("Error fetching radovi:", error);
      }
    };
    loadRadovi();
  }, [t]);

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1;
    const totalSlides = radovi.length;
    setIsPrevArrowVisible(current > 0);
    setIsNextArrowVisible(current + slidesToShow < totalSlides);
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth > 768 ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  };

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
        <div>
          <h2 className={styles.sectionTitle}>
            {t("researchSection.papersTitle")}
          </h2>
          <p className={styles.sectionSubtitle}>
            {t("researchSection.papersubheading")}
          </p>
        </div>

        <Link
          to={`/istrazivanje/osoblje/naucni-radovi`}
          style={{ textDecoration: "none", color: "inherit" }}
          className="section__buttons"
        >
          <ButtonComponent
            label={"Svi radovi →"}
            color="#ffffff"
            backgroundColor="#051435"
          />
        </Link>
      </div>

      <Slider {...sliderSettings}>
        {radovi.map((paper, idx) => (
          <div key={idx} style={{ padding: "0 15px" }}>
            <div className={styles.paperCard}>
              {paper.paper_url && (
                <a
                  href={paper.paper_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.externalLink}
                  title="Open paper"
                >
                  <FaExternalLinkAlt />
                </a>
              )}

              <h5 className={styles.paperTitle}>{paper.title}</h5>

              <p className={styles.authors}>
                {/* Change to paper.staff_only if you want to see only teaching staff authors */}
                {paper.authors.map((author, i) => {
                  const isLast = i === paper.authors.length - 1;
                  const separator = !isLast ? " • " : "";
                  if (author.slug) {
                    return (
                      <span key={i}>
                        <Link
                          to={author.slug}
                          className={styles.authorLink}
                          state={{
                            from: "osoblje/radovi",
                          }}
                        >
                          {author.name}
                        </Link>
                        {separator}
                      </span>
                    );
                  } else {
                    return <span key={i}>{author.name + separator}</span>;
                  }
                })}
              </p>

              <p className={styles.dateLine}>{paper.date_published}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NaucniRadoviSlider;
