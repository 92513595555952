import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { fetchAllRezultatiIspita } from "../../../services/apiService";
import "bootstrap/dist/css/bootstrap.min.css";
import style from "./RezultatiIspita.module.css";
import SharedPagination from "../../../components/SharedPagination";

const RezultatiIspitaComponent = () => {
  const [rezultatiIspita, setRezultatiIspita] = useState([]);
  const [rezultatiIspitaNum, setRezultatiIspitaNum] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [pageSize, setPageSize] = useState(10); 

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;

  const totalPages = Math.ceil(rezultatiIspitaNum / pageSize);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await fetchAllRezultatiIspita(page, filterName);
      setRezultatiIspitaNum(data.count);
      setRezultatiIspita(data.results);
      if (data.page_size) {
        setPageSize(data.page_size); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filterName]);

  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    window.scrollTo(0, 0);
  };

  const handleTitleFilterChange = (e) => {
    setFilterName(e.target.value);
    navigate("?page=1");
  };

  const renderProfessorName = (professor) => {
    if (!professor) return null;
    const { abbrevation, full_name, slug_or_url, is_slug } = professor;
    if (!slug_or_url) {
      return (
        <span>
          {abbrevation}
          <b>{full_name}</b>
        </span>
      );
    }

    if (is_slug) {
      return (
        <Link
          to={`/fakultet/nastavno-osoblje/${slug_or_url}`}
          className={style.professorLink}
        >
          <span>
            {abbrevation}
            <b>{full_name}</b>
          </span>
        </Link>
      );
    } else {
      return (
        <a
          href={slug_or_url}
          target="_blank"
          rel="noopener noreferrer"
          className={style.professorLink}
        >
          <span>
            {abbrevation}
            <b>{full_name}</b>
          </span>
        </a>
      );
    }
  };

  return (
    <div className="mt-4 bg-white">
      <div className="container py-5">
        <div className="d-flex flex-column flex-md-row align-items-center border-bottom pb-3 mb-4">
          <div className="col-12 d-flex justify-content-end">
            <div className={`${style.inputField} input-group`}>
              <input
                type="text"
                className="form-control"
                placeholder={t("nastavnoOsobljeLista.search")}
                onChange={handleTitleFilterChange}
              />
            </div>
          </div>
        </div>

        {/* Loading Spinner */}
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="row">
            {rezultatiIspita.length === 0 ? (
              <div className={style.noDataContainer}>
                <p>{t("nastavnoOsobljeLista.noData")}</p>
              </div>
            ) : (
              rezultatiIspita.map((exam, idx) => (
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={idx}>
                  <div className="card h-100 p-4">
                    <div className="d-flex justify-content-end">
                      <span className="badge bg-light text-dark fw-normal px-2">
                        {exam.result_date}
                      </span>
                    </div>

                    <h5 className={`fw-bold mt-4 mb-0 ${style.examSubject}`}>
                      {exam.subject}
                    </h5>
                    <p className="mb-1 fs-6 d-flex align-items-center">
                      <FaRegClock className="me-1" />
                      {t("rezultatiIspita.term")}
                      <span>
                        : <b>{exam.exam_detail}</b>
                      </span>
                    </p>
                    <p className="d-flex align-items-center">
                      <FaRegCalendarAlt className="me-1" />
                      {t("rezultatiIspita.held")}
                      <span>
                        : <b>{exam.exam_date}</b>
                      </span>
                    </p>
                    <hr />
                    <div className="d-flex align-items-center">
                      {exam.professor && exam.professor.profile_image && (
                        <img
                          src={exam.professor.profile_image}
                          alt="Professor"
                          className={`me-2 ${style.professorImage} professor-image`}
                        />
                      )}
                      <span className="ms-2">
                        {renderProfessorName(exam.professor)}
                      </span>
                    </div>
                    <hr />

                    <div className="d-flex justify-content-end me-2">
                      <a
                        href={exam.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.downloadLink}
                      >
                        {t("rezultatiIspita.download")}
                        <i className="bi bi-download ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        <SharedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RezultatiIspitaComponent;
