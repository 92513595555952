import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { FaChevronDown, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

import {
  getStaffList,
  addProject,
  updateProject,
} from "../../../services/userProfileService";

const AddProjekat = ({ onCancel, existingData, onProjectUpdate }) => {
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [loadingStaff, setLoadingStaff] = useState(false);

  // For the single manager (Voditelj)
  const [manager, setManager] = useState({
    type: "staff",
    staffId: null,
    externalName: "",
  });
  const [openManagerDropdown, setOpenManagerDropdown] = useState(false);
  const [managerSearch, setManagerSearch] = useState("");

  // For the project members array
  const [members, setMembers] = useState([]);
  const [openMembersDropdownIndex, setOpenMembersDropdownIndex] =
    useState(null);
  const [memberSearch, setMemberSearch] = useState("");

  // Server validation errors
  const [serverErrors, setServerErrors] = useState({});

  // Toggling the "English fields" section
  const [showEnglishFields, setShowEnglishFields] = useState(false);

  // Form data
  const [formData, setFormData] = useState({
    title: "",
    description_bs: "",
    funder: "",
    year_started: "",
    year_finished: "",
    url: "",
    title_en: "",
    description_en: "",
    funder_en: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  // --------------------------------------------------------------------------
  // Fetch staff on mount
  // --------------------------------------------------------------------------
  useEffect(() => {
    fetchStaff();
  }, []);

  // --------------------------------------------------------------------------
  // Populate form if editing an existing project
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (!existingData) return;

    setFormData({
      title: existingData.title || "",
      description_bs: existingData.description_bs || "",
      funder: existingData.funder || "",
      year_started: existingData.year_started || "",
      year_finished: existingData.year_finished || "",
      url: existingData.url || "",
      title_en: existingData.title_en || "",
      description_en: existingData.description_en || "",
      funder_en: existingData.funder_en || "",
    });

    // Manager
    if (existingData.manager) {
      if (existingData.manager.teaching_staff_id) {
        // Manager is an internal staff member
        setManager({
          type: "staff",
          staffId: existingData.manager.teaching_staff_id,
          externalName: "",
        });
      } else if (existingData.manager.name) {
        // Manager is external
        setManager({
          type: "external",
          staffId: null,
          externalName: existingData.manager.name,
        });
      }
    }

    // Members
    if (Array.isArray(existingData.members)) {
      const convertedMembers = existingData.members.map((m) => {
        if (m.teaching_staff_id) {
          return {
            type: "staff",
            staffId: m.teaching_staff_id,
            externalName: "",
          };
        }
        return {
          type: "external",
          staffId: null,
          externalName: m.name || "",
        };
      });
      setMembers(convertedMembers);
    }
  }, [existingData]);

  // --------------------------------------------------------------------------
  // Get staff list
  // --------------------------------------------------------------------------
  const fetchStaff = async () => {
    setLoadingStaff(true);
    try {
      const data = await getStaffList(); // Adjust if your endpoint differs
      setStaff(data);
      setFilteredStaff(data);
    } catch (error) {
      console.error("Greška pri učitavanju nastavnog osoblja:", error);
    } finally {
      setLoadingStaff(false);
    }
  };

  // --------------------------------------------------------------------------
  // Handle form text changes
  // --------------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // --------------------------------------------------------------------------
  // Manager (Voditelj) handling
  // --------------------------------------------------------------------------
  const handleManagerDropdownToggle = (isOpen) => {
    if (isOpen) {
      setOpenManagerDropdown(true);
      setManagerSearch("");
      setFilteredStaff(staff);
    } else {
      setOpenManagerDropdown(false);
    }
  };

  const handleManagerTypeChange = (newType) => {
    // Clear any existing data when switching type
    setManager({ type: newType, staffId: null, externalName: "" });
  };

  const handleManagerExternalChange = (val) => {
    setManager((prev) => ({ ...prev, externalName: val }));
  };

  const handleManagerSearch = (e) => {
    const value = e.target.value;
    setManagerSearch(value);

    if (!value) {
      setFilteredStaff(staff);
    } else {
      const filtered = staff.filter((prof) =>
        prof.full_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStaff(filtered);
    }
  };

  const getAvailableStaffForManager = () => {
    // Filter by managerSearch.
    return staff.filter((prof) =>
      prof.full_name.toLowerCase().includes(managerSearch.toLowerCase())
    );
  };

  const handleManagerSelect = (chosenStaff) => {
    setManager({
      type: "staff",
      staffId: chosenStaff.id,
      externalName: "",
    });
    setManagerSearch("");
    setOpenManagerDropdown(false);
  };

  // --------------------------------------------------------------------------
  // Members handling (Članovi)
  // --------------------------------------------------------------------------

  // Add a new row
  const addMemberRow = () => {
    setMembers((prev) => [
      ...prev,
      { type: "staff", staffId: null, externalName: "" },
    ]);
  };

  // Remove a row
  const removeMemberRow = (index) => {
    setMembers((prev) => prev.filter((_, i) => i !== index));
  };

  const handleMemberTypeChange = (index, newType) => {
    setMembers((prev) =>
      prev.map((m, idx) =>
        idx === index ? { type: newType, staffId: null, externalName: "" } : m
      )
    );
  };

  const handleMemberExternalChange = (index, val) => {
    setMembers((prev) =>
      prev.map((m, idx) => (idx === index ? { ...m, externalName: val } : m))
    );
  };

  const handleMemberDropdownToggle = (isOpen, rowIndex) => {
    if (isOpen) {
      setOpenMembersDropdownIndex(rowIndex);
      setMemberSearch("");
      setFilteredStaff(staff);
    } else {
      setOpenMembersDropdownIndex(null);
    }
  };

  const handleMemberSearch = (e) => {
    const value = e.target.value;
    setMemberSearch(value);
    setFilteredStaff(
      staff.filter((prof) =>
        prof.full_name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const getAvailableStaffForMember = (rowIndex) => {
    const managerId =
      manager.type === "staff" && manager.staffId ? manager.staffId : null;

    const usedMemberIds = members
      .map((m, idx) => {
        if (idx !== rowIndex && m.type === "staff" && m.staffId) {
          return m.staffId;
        }
        return null;
      })
      .filter((id) => id !== null);

    const currentRowStaffId =
      members[rowIndex].type === "staff" ? members[rowIndex].staffId : null;

    return staff.filter((prof) => {
      if (managerId && prof.id === managerId) {
        return false;
      }

      if (usedMemberIds.includes(prof.id) && prof.id !== currentRowStaffId) {
        return false;
      }

      return prof.full_name.toLowerCase().includes(memberSearch.toLowerCase());
    });
  };

  const handleMemberSelect = (index, chosenStaff) => {
    setMembers((prev) =>
      prev.map((m, idx) =>
        idx === index
          ? { type: "staff", staffId: chosenStaff.id, externalName: "" }
          : m
      )
    );
    setMemberSearch("");
    setOpenMembersDropdownIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setServerErrors({});

    const submissionData = new FormData();
    submissionData.append("title", formData.title.trim());
    submissionData.append("description_bs", formData.description_bs || "");
    submissionData.append("funder", formData.funder.trim());
    submissionData.append("year_started", formData.year_started || "");
    submissionData.append("year_finished", formData.year_finished || null);
    submissionData.append("url", formData.url || "");
    submissionData.append("title_en", formData.title_en || "");
    submissionData.append("description_en", formData.description_en || "");
    submissionData.append("funder_en", formData.funder_en || "");

    let managerPayload = {};
    if (manager.type === "staff") {
      managerPayload = { nastavnik: manager.staffId, external_voditelj: "" };
    } else {
      managerPayload = {
        external_voditelj: manager.externalName.trim(),
        nastavnik: null,
      };
    }
    submissionData.append("manager", JSON.stringify(managerPayload));

    const membersPayload = members.map((mem) => {
      if (mem.type === "staff") {
        return { nastavnik: mem.staffId, external_clan: "" };
      }
      return { external_clan: mem.externalName.trim(), nastavnik: null };
    });
    submissionData.append("members", JSON.stringify(membersPayload));

    try {
      setIsLoading(true);

      if (existingData && existingData.id) {
        await updateProject(existingData.id, submissionData);
        toast.success("Projekat uspješno ažuriran!");
      } else {
        await addProject(submissionData);
        toast.success("Projekat uspješno dodan!");
      }

      onProjectUpdate();
      onCancel();
    } catch (error) {
      console.error("Greška pri snimanju projekta:", error);

      if (error?.response?.data?.detail) {
        const detail = error.response.data.detail;
        const newErrors = {};
      
        Object.keys(detail).forEach((field) => {
          const fieldValue = detail[field];
      
          if (Array.isArray(fieldValue)) {
            // Check if it's an array of objects
            if (typeof fieldValue[0] === "object" && fieldValue[0] !== null) {
              // Collect all non_field_errors from objects
              const messages = fieldValue
                .map((obj) => {
                  if (obj.non_field_errors) {
                    return obj.non_field_errors.join(", ");
                  }
                  return null;
                })
                .filter((msg) => msg !== null);
      
              newErrors[field] = messages.join("\n");
            } else {
              // It's a plain array of strings
              newErrors[field] = fieldValue.join(", ");
            }
          } else if (typeof fieldValue === "object" && fieldValue !== null) {
            if (fieldValue.non_field_errors) {
              newErrors[field] = fieldValue.non_field_errors.join("\n");
            }
          }
        });
      
        setServerErrors(newErrors);
      } else {
        toast.error("Došlo je do greške na serveru.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled =
    isLoading ||
    !formData.title.trim() ||
    !formData.funder.trim() ||
    !formData.year_started ||
    (manager.type === "staff" && !manager.staffId) ||
    (manager.type === "external" && !manager.externalName.trim());

  return (
    <div className="dodaj-projekat-container p-4 pt-3">
      <h2 className="mb-3">
        {existingData ? "Uredi projekat" : "Dodaj novi projekat"}
      </h2>
      <Card className="p-4 shadow-sm pb-5">
        <Form onSubmit={handleSubmit} className="mb-3">
          <Form.Group className="mb-3">
            <Form.Label>
              Naziv projekta <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Naziv projekta može biti na bosanskom ili engleskom"
            />
            {serverErrors.title && (
              <div className="text-danger mt-1">{serverErrors.title}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Opis projekta (bosanski)</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="description_bs"
              value={formData.description_bs}
              onChange={handleChange}
              placeholder="Do 1000 karaktera"
            />
            {serverErrors.description_bs && (
              <div className="text-danger mt-1">
                {serverErrors.description_bs}
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Pokrovitelj projekta <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="funder"
              value={formData.funder}
              onChange={handleChange}
              placeholder="npr. Ministarstvo za odgoj i obrazovanje Kantona Sarajevo"
            />
            {serverErrors.funder && (
              <div className="text-danger mt-1">{serverErrors.funder}</div>
            )}
          </Form.Group>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Godina početka <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="year_started"
                  value={formData.year_started}
                  onChange={handleChange}
                  placeholder="npr. 2023"
                />
                {serverErrors.year_started && (
                  <div className="text-danger mt-1">
                    {serverErrors.year_started}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Godina završetka (opcionalno)</Form.Label>
                <Form.Control
                  type="number"
                  name="year_finished"
                  value={formData.year_finished}
                  onChange={handleChange}
                  placeholder="Ako projekat još traje, ostavite prazno"
                />
                {serverErrors.year_finished && (
                  <div className="text-danger mt-1">
                    {serverErrors.year_finished}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>URL projekta (opcionalno)</Form.Label>
            <Form.Control
              type="text"
              name="url"
              value={formData.url}
              onChange={handleChange}
              placeholder="https://example.com/projekat"
            />
            {serverErrors.url && (
              <div className="text-danger mt-1">{serverErrors.url}</div>
            )}
          </Form.Group>

          <Button
            variant="secondary"
            className="mb-3"
            onClick={() => setShowEnglishFields(!showEnglishFields)}
          >
            {showEnglishFields ? "Sakrij ENG polja" : "Prikaži ENG polja"}
          </Button>

          {showEnglishFields && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Naziv projekta EN (opcionalno)</Form.Label>
                <Form.Control
                  type="text"
                  name="title_en"
                  value={formData.title_en}
                  onChange={handleChange}
                  placeholder="Ako je originalni naziv na bosanskom, prevedite naslov i unesite na engleskom"
                />
                {serverErrors.title_en && (
                  <div className="text-danger mt-1">
                    {serverErrors.title_en}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opis projekta EN (opcionalno)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description_en"
                  value={formData.description_en}
                  onChange={handleChange}
                  placeholder="Up to 1000 characters"
                />
                {serverErrors.description_en && (
                  <div className="text-danger mt-1">
                    {serverErrors.description_en}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Pokrovitelj projekta EN (opcionalno)</Form.Label>
                <Form.Control
                  type="text"
                  name="funder_en"
                  value={formData.funder_en}
                  onChange={handleChange}
                  placeholder="npr. Ministry of Education of Sarajevo Canton"
                />
                {serverErrors.funder_en && (
                  <div className="text-danger mt-1">
                    {serverErrors.funder_en}
                  </div>
                )}
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3">
            <Form.Label>
              Voditelj projekta <span className="text-danger">*</span>
            </Form.Label>
            <div className="d-flex align-items-start" style={{ gap: "10px" }}>
              <Form.Select
                style={{ maxWidth: "120px" }}
                value={manager.type}
                onChange={(e) => handleManagerTypeChange(e.target.value)}
              >
                <option value="staff">Nastavnik</option>
                <option value="external">Vanjski</option>
              </Form.Select>

              {manager.type === "staff" ? (
                <Dropdown
                  className="flex-grow-1"
                  show={openManagerDropdown}
                  onToggle={handleManagerDropdownToggle}
                  autoClose="outside"
                >
                  <Dropdown.Toggle as={InputGroup}>
                    <FormControl
                      placeholder="Odaberite nastavnika"
                      readOnly
                      value={
                        manager.staffId
                          ? staff.find((s) => s.id === manager.staffId)
                              ?.full_name || ""
                          : ""
                      }
                    />
                    <InputGroup.Text>
                      <FaChevronDown />
                    </InputGroup.Text>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <FormControl
                      placeholder="Pretraži nastavnika..."
                      value={managerSearch}
                      onChange={handleManagerSearch}
                      onClick={(e) => e.stopPropagation()}
                      className="dropdown-search-input"
                    />
                    {loadingStaff ? (
                      <Dropdown.Item as="div" disabled>
                        Učitavanje...
                      </Dropdown.Item>
                    ) : getAvailableStaffForManager().length === 0 ? (
                      <Dropdown.Item as="div" disabled>
                        Nema rezultata
                      </Dropdown.Item>
                    ) : (
                      getAvailableStaffForManager().map((prof) => (
                        <Dropdown.Item
                          key={prof.id}
                          onClick={() => handleManagerSelect(prof)}
                        >
                          {prof.short_rank + prof.full_name}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <FormControl
                  placeholder="Unesite ime i prezime vanjskog voditelja"
                  value={manager.externalName}
                  onChange={(e) => handleManagerExternalChange(e.target.value)}
                />
              )}
            </div>
            {serverErrors.manager && (
              <div
                className="text-danger mt-1"
                dangerouslySetInnerHTML={{
                  __html: serverErrors.manager.replace(/\n/g, "<br/>"),
                }}
              />
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Članovi projekta (opcionalno)</Form.Label>
            {members.map((member, index) => (
              <div
                key={index}
                className="d-flex align-items-start mb-2"
                style={{ gap: "10px" }}
              >
                <Form.Select
                  style={{ maxWidth: "120px" }}
                  value={member.type}
                  onChange={(e) =>
                    handleMemberTypeChange(index, e.target.value)
                  }
                >
                  <option value="staff">Nastavnik</option>
                  <option value="external">Vanjski</option>
                </Form.Select>

                {member.type === "staff" ? (
                  <Dropdown
                    className="flex-grow-1"
                    show={openMembersDropdownIndex === index}
                    onToggle={(isOpen) =>
                      handleMemberDropdownToggle(isOpen, index)
                    }
                    autoClose="outside"
                  >
                    <Dropdown.Toggle as={InputGroup}>
                      <FormControl
                        placeholder="Odaberite nastavnika"
                        readOnly
                        value={
                          member.staffId
                            ? staff.find((s) => s.id === member.staffId)
                                ?.full_name || ""
                            : ""
                        }
                      />
                      <InputGroup.Text>
                        <FaChevronDown />
                      </InputGroup.Text>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      <FormControl
                        placeholder="Pretraži nastavnika..."
                        value={memberSearch}
                        onChange={handleMemberSearch}
                        onClick={(e) => e.stopPropagation()}
                        className="dropdown-search-input"
                      />
                      {loadingStaff ? (
                        <Dropdown.Item as="div" disabled>
                          Učitavanje...
                        </Dropdown.Item>
                      ) : getAvailableStaffForMember(index).length === 0 ? (
                        <Dropdown.Item as="div" disabled>
                          Nema rezultata
                        </Dropdown.Item>
                      ) : (
                        getAvailableStaffForMember(index).map((prof) => (
                          <Dropdown.Item
                            key={prof.id}
                            onClick={() => handleMemberSelect(index, prof)}
                          >
                            {prof.short_rank + prof.full_name}
                          </Dropdown.Item>
                        ))
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <FormControl
                    placeholder="Unesite ime i prezime vanjskog člana"
                    value={member.externalName}
                    onChange={(e) =>
                      handleMemberExternalChange(index, e.target.value)
                    }
                  />
                )}

                <Button
                  variant="danger"
                  onClick={() => removeMemberRow(index)}
                  style={{ height: "38px" }}
                >
                  <FaTrash />
                </Button>
              </div>
            ))}
            <Button variant="secondary m-2" onClick={addMemberRow}>
              + Dodaj člana
            </Button>

            {serverErrors.members && (
              <div
                className="text-danger mt-1"
                dangerouslySetInnerHTML={{
                  __html: serverErrors.members.replace(/\n/g, "<br/>"),
                }}
              />
            )}
          </Form.Group>

          {/* Action buttons */}
          <div className="d-flex justify-content-end mt-4 mb-4 pt-4">
            <Button
              variant="outline-danger"
              onClick={onCancel}
              className="me-2"
            >
              Otkaži
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitDisabled}>
              {isLoading
                ? "Obrada..."
                : existingData
                ? "Sačuvaj izmjene"
                : "Dodaj projekat"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddProjekat;
