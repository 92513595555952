import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { FaChevronDown, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  getStaffList,
  addPaper,
  updatePaper,
} from "../../../services/userProfileService";

const AddPaper = ({ onCancel, existingData, onPaperUpdate }) => {
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [staffSearch, setStaffSearch] = useState("");
  const [loadingStaff, setLoadingStaff] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [authors, setAuthors] = useState([
    { type: "staff", staffId: null, externalName: "" },
  ]);

  const [serverErrors, setServerErrors] = useState({});

  const [formData, setFormData] = useState({
    title: "",
    paper_type: "",
    journal_name: "",
    doi: "",
    url: "",
  });

  const [datePublished, setDatePublished] = useState(null);

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (existingData) {
      setFormData({
        title: existingData.title || "",
        paper_type: existingData.paper_type || "",
        journal_name: existingData.journal_name || "",
        doi: existingData.doi || "",
        url: existingData.url || "",
      });

      if (existingData.date_published) {
        const parsed = new Date(existingData.date_published);
        if (!isNaN(parsed.getTime())) {
          setDatePublished(parsed);
        } else {
          setDatePublished(null);
        }
      }

      if (
        Array.isArray(existingData.authors) &&
        existingData.authors.length > 0
      ) {
        const converted = existingData.authors.map((auth) => {
          if (auth.teaching_staff_id !== null) {
            return {
              type: "staff",
              staffId: auth.teaching_staff_id,
              externalName: "",
            };
          } else {
            return {
              type: "external",
              staffId: null,
              externalName: auth.name || "",
            };
          }
        });
        setAuthors(converted);
      }
    }
  }, [existingData]);

  const fetchStaff = async () => {
    setLoadingStaff(true);
    try {
      const data = await getStaffList();
      setStaff(data);
      setFilteredStaff(data);
    } catch (error) {
      console.error("Greška pri učitavanju nastavnog osoblja:", error);
    } finally {
      setLoadingStaff(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setDatePublished(date);
  };

  const handleAuthorTypeChange = (index, newType) => {
    setAuthors((prev) =>
      prev.map((auth, idx) =>
        idx === index
          ? { type: newType, staffId: null, externalName: "" }
          : auth
      )
    );
  };

  const handleAuthorExternalChange = (index, val) => {
    setAuthors((prev) =>
      prev.map((auth, idx) =>
        idx === index ? { ...auth, externalName: val } : auth
      )
    );
  };

  const addAuthorRow = () => {
    setAuthors((prev) => [
      ...prev,
      { type: "staff", staffId: null, externalName: "" },
    ]);
  };

  const removeAuthorRow = (index) => {
    setAuthors((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleStaffSearch = (e) => {
    const value = e.target.value;
    setStaffSearch(value);

    if (!value) {
      setFilteredStaff(staff);
    } else {
      const filtered = staff.filter((prof) =>
        prof.full_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStaff(filtered);
    }
  };

  const getAvailableStaffForRow = (rowIndex) => {
    const usedStaffIds = authors
      .filter((a, idx) => idx !== rowIndex && a.type === "staff" && a.staffId)
      .map((a) => a.staffId);

    const rowStaffId = authors[rowIndex].staffId;

    return staff
      .filter((prof) => {
        return !usedStaffIds.includes(prof.id) || prof.id === rowStaffId;
      })
      .filter((prof) =>
        prof.full_name.toLowerCase().includes(staffSearch.toLowerCase())
      );
  };

  const handleStaffSelect = (index, chosenStaff) => {
    setAuthors((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? { type: "staff", staffId: chosenStaff.id, externalName: "" }
          : item
      )
    );
    setStaffSearch("");
    setFilteredStaff(staff);
    setOpenDropdownIndex(null);
  };

  const handleStaffDropdownToggle = (isOpen, rowIndex) => {
    if (isOpen) {
      setOpenDropdownIndex(rowIndex);
      setStaffSearch("");
      setFilteredStaff(staff);
    } else {
      setOpenDropdownIndex(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setServerErrors({});

    const submissionData = new FormData();
    submissionData.append("title", formData.title);
    submissionData.append("paper_type", formData.paper_type);
    submissionData.append("journal_name", formData.journal_name);
    submissionData.append("doi", formData.doi);
    submissionData.append("url", formData.url || "");

    if (datePublished) {
      const isoString = datePublished.toISOString().split("T")[0];
      submissionData.append("date_published", isoString);
    } else {
      submissionData.append("date_published", "");
    }

    const authorsPayload = authors.map((auth) => {
      if (auth.type === "staff") {
        return { nastavnik: auth.staffId };
      } else {
        return { external_author: auth.externalName };
      }
    });
    submissionData.append("authors", JSON.stringify(authorsPayload));

    try {
      setIsLoading(true);

      if (existingData && existingData.id) {
        await updatePaper(existingData.id, submissionData);
      } else {
        await addPaper(submissionData);
      }

      toast.success("Rad uspješno sačuvan!");
      onPaperUpdate();
      onCancel();
    } catch (error) {
      if (error?.response?.data?.detail) {
        const detail = error.response.data.detail;
        const newErrors = {};

        if (detail.title) {
          newErrors.title = detail.title.join(", ");
        }

        if (detail.paper_type) {
          newErrors.paper_type = detail.paper_type.join(", ");
        }

        if (detail.journal_name) {
          newErrors.journal_name = detail.journal_name.join(", ");
        }

        if (detail.doi) {
          newErrors.doi = detail.doi.join(", ");
        }

        if (detail.url) {
          newErrors.url = detail.url.join(", ");
        }

        if (detail.date_published) {
          newErrors.date_published = detail.date_published.join(", ");
        }

        if (detail.authors) {
          let msgs = [];

          // Collect general non_field_errors if present
          if (Array.isArray(detail.authors.non_field_errors)) {
            msgs.push(...detail.authors.non_field_errors);
          }

          // Loop through each author object inside the array
          if (Array.isArray(detail.authors)) {
            detail.authors.forEach((item) => {
              if (
                item &&
                typeof item === "object" &&
                Object.keys(item).length > 0
              ) {
                // Catch generic non_field_errors
                if (Array.isArray(item.non_field_errors)) {
                  msgs.push(...item.non_field_errors);
                }

                // Catch field-level errors like external_author
                if (Array.isArray(item.external_author)) {
                  msgs.push(...item.external_author);
                }
              }
            });
          }

          if (msgs.length > 0) {
            newErrors.authors = msgs.join("\n"); // Display one error per line
          }
        }

        setServerErrors(newErrors);
      } else {
        toast.error("Došlo je do greške na serveru.");
        console.error("Error while saving paper:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled =
    isLoading ||
    !formData.title.trim() ||
    !formData.paper_type ||
    !formData.journal_name.trim() ||
    !formData.doi.trim() ||
    !datePublished;

  return (
    <div className="dodaj-rad-container p-4 pt-3">
      <h2 className="mb-3">
        {existingData ? "Uredi podatke o radu" : "Dodaj novi naučni rad"}
      </h2>
      <Card className="p-4 shadow-sm pb-5">
        <Form onSubmit={handleSubmit} className="mb-3">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Naslov rada</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Unesite naslov rada"
                />
                {serverErrors.title && (
                  <div className="text-danger mt-1">{serverErrors.title}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Vrsta rada</Form.Label>
                <Form.Select
                  name="paper_type"
                  value={formData.paper_type}
                  onChange={handleInputChange}
                >
                  <option value="">-- Odaberite vrstu rada --</option>
                  <option value="Journal Article">Journal Article</option>
                  <option value="Conference Paper">Conference Paper</option>
                  <option value="Book Chapter">Book Chapter</option>
                </Form.Select>
                {serverErrors.paper_type && (
                  <div className="text-danger mt-1">
                    {serverErrors.paper_type}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Naziv žurnala</Form.Label>
                <Form.Control
                  type="text"
                  name="journal_name"
                  value={formData.journal_name}
                  onChange={handleInputChange}
                  placeholder="Unesite naziv žurnala"
                />
                {serverErrors.journal_name && (
                  <div className="text-danger mt-1">
                    {serverErrors.journal_name}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Datum objavljivanja</Form.Label>
                <div>
                  <DatePicker
                    selected={datePublished}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    placeholderText="Odaberite datum"
                    className="form-control"
                  />
                </div>
                {serverErrors.date_published && (
                  <div className="text-danger mt-1">
                    {serverErrors.date_published}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>DOI</Form.Label>
                <Form.Control
                  type="text"
                  name="doi"
                  value={formData.doi}
                  onChange={handleInputChange}
                  placeholder="npr. 10.55534 ili https://doi.org/10.55534"
                />
                {serverErrors.doi && (
                  <div className="text-danger mt-1">{serverErrors.doi}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>URL rada (opcionalno)</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  placeholder="https://example.com"
                />
                {serverErrors.url && (
                  <div className="text-danger mt-1">{serverErrors.url}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div className="mb-3">
            <Form.Label>Autori</Form.Label>
            {authors.map((author, index) => (
              <div
                key={index}
                className="d-flex align-items-start mb-2"
                style={{ gap: "10px" }}
              >
                <Form.Select
                  style={{ maxWidth: "120px" }}
                  value={author.type}
                  onChange={(e) =>
                    handleAuthorTypeChange(index, e.target.value)
                  }
                >
                  <option value="staff">Nastavnik</option>
                  <option value="external">Vanjski</option>
                </Form.Select>

                {author.type === "staff" ? (
                  <Dropdown
                    className="flex-grow-1"
                    show={openDropdownIndex === index}
                    onToggle={(isOpen) =>
                      handleStaffDropdownToggle(isOpen, index)
                    }
                    autoClose="outside"
                  >
                    <Dropdown.Toggle as={InputGroup}>
                      <FormControl
                        placeholder="Odaberite nastavnika"
                        readOnly
                        value={
                          author.staffId
                            ? staff.find((s) => s.id === author.staffId)
                                ?.full_name || "..."
                            : ""
                        }
                      />
                      <InputGroup.Text>
                        <FaChevronDown />
                      </InputGroup.Text>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      <FormControl
                        placeholder="Pretraži nastavnika..."
                        value={staffSearch}
                        onChange={handleStaffSearch}
                        onClick={(e) => e.stopPropagation()}
                        className="dropdown-search-input"
                      />
                      {loadingStaff ? (
                        <Dropdown.Item as="div" disabled>
                          Učitavanje...
                        </Dropdown.Item>
                      ) : getAvailableStaffForRow(index).length === 0 ? (
                        <Dropdown.Item as="div" disabled>
                          Nema rezultata
                        </Dropdown.Item>
                      ) : (
                        getAvailableStaffForRow(index).map((prof) => (
                          <Dropdown.Item
                            key={prof.id}
                            onClick={() => handleStaffSelect(index, prof)}
                          >
                            {prof.short_rank + prof.full_name}
                          </Dropdown.Item>
                        ))
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <FormControl
                    placeholder="Unesite ime i prezime vanjskog autora"
                    value={author.externalName}
                    onChange={(e) =>
                      handleAuthorExternalChange(index, e.target.value)
                    }
                  />
                )}

                {authors.length > 1 && (
                  <Button
                    variant="danger"
                    onClick={() => removeAuthorRow(index)}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            ))}

            <Button variant="secondary" onClick={addAuthorRow}>
              + Dodaj autora
            </Button>
            {serverErrors.authors && (
              <div
                className="text-danger mt-1"
                dangerouslySetInnerHTML={{
                  __html: serverErrors.authors.replace(/\n/g, "<br/>"),
                }}
              />
            )}
          </div>

          <div className="d-flex justify-content-end mt-4 mb-4 pt-4">
            <Button
              variant="outline-danger"
              onClick={onCancel}
              className="me-2"
            >
              Otkaži
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitDisabled}>
              {isLoading
                ? "Obrada..."
                : existingData
                ? "Sačuvaj izmjene"
                : "Dodaj rad"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddPaper;
