import React, { useState, useEffect } from "react";
import {
  Table,
  Spinner,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  getProjects,
  deleteProject,
  getProjectById,
} from "../../../services/userProfileService";

import SharedPagination from "../../../components/SharedPagination";
import AddProjekat from "./AddProjekat";
import "./ProjektiList.css";

const ProjektiList = () => {
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); 

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);

  const [searchQuery, setSearchQuery] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [editingProject, setEditingProject] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const data = await getProjects(page, searchQuery);
      setProjects(data.results);
      setCount(data.count);
      if (data.page_size) {
        setPageSize(data.page_size); 
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Greška pri učitavanju projekata.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [page, searchQuery]);

  const handleStaffProjects = () => {
    const slug = localStorage.getItem("slug");
    navigate(`/fakultet/nastavno-osoblje/${slug}`, {
      state: { from: "osoblje/projekti" },
    });
  };

  const handleAllProjects = () => {
    navigate("/istrazivanje/osoblje/projekti");
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setSearchParams({ page: newPage });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchParams({ page: 1 });
  };

  const handleAddProject = () => {
    setShowForm(true);
    setEditingProject(null);
  };

  const handleEditProject = async (id) => {
    try {
      setLoading(true);
      const projectData = await getProjectById(id);
      setEditingProject(projectData);
      setShowForm(true);
    } catch (error) {
      toast.error("Greška pri učitavanju projekta.");
    } finally {
      setLoading(false);
    }
  };

  const handleProjectUpdated = () => {
    fetchProjects();
    setShowForm(false);
    setEditingProject(null);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedProjectId(id);
    setShowDeleteModal(true);
  };

  const removeProject = async () => {
    if (!selectedProjectId) return;
    try {
      setShowDeleteModal(false);
      setLoading(true);
      await deleteProject(selectedProjectId);
      toast.success("Projekat uspješno obrisan!");
      fetchProjects();
    } catch (error) {
      toast.error("Greška pri brisanju projekta.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3" style={{ maxHeight: "max-content" }}>
      <div className="mb-3">
        <h2>Projekti</h2>
        {!showForm && (
          <div className="d-flex justify-content-end user-panel-projekti-table-btns">
            <Button
              onClick={handleStaffProjects}
              variant="success"
              className="me-2"
            >
              Pogledaj svoje projekte
            </Button>
            <Button onClick={handleAllProjects} variant="info" className="me-2">
              Pogledaj sve projekte
            </Button>
            <Button onClick={handleAddProject} variant="primary">
              Dodaj novi projekat
            </Button>
          </div>
        )}
      </div>

      {showForm ? (
        <AddProjekat
          onCancel={() => {
            setShowForm(false);
            setEditingProject(null);
          }}
          existingData={editingProject}
          onProjectUpdate={handleProjectUpdated}
        />
      ) : (
        <>
          <InputGroup className="mb-3 projekti-search">
            <FormControl
              placeholder="Pretraži projekte..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          <div className="table-responsive">
            <Table bordered hover className="bg-white shadow-sm projekti-table">
              <thead>
                <tr>
                  <th className="project-th-title">Naslov</th>
                  <th>Voditelj projekta</th>
                  <th>Godina početka</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : projects.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Nema dostupnih projekata.
                    </td>
                  </tr>
                ) : (
                  projects.map((project) => (
                    <tr key={project.id}>
                      <td className="project-title">{project.title}</td>
                      <td>{project.manager?.name}</td>
                      <td>{project.year_started}</td>
                      <td>
                        {project.can_modify ? (
                          <>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="me-2"
                              onClick={() =>
                                handleDeleteConfirmation(project.id)
                              }
                            >
                              <FaTrash />
                            </Button>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              onClick={() => handleEditProject(project.id)}
                            >
                              <FaEdit />
                            </Button>
                          </>
                        ) : (
                          "N/A*"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <p className="text-muted fst-italic">
              * Projekte mogu uređivati samo voditelji i/li kreatori pojedinog projekta.
            </p>
          </div>

          <SharedPagination
            currentPage={page}
            totalPages={Math.ceil(count / pageSize)}
            onPageChange={handlePageChange}
          />
        </>
      )}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Body className="text-center projekti-modal">
          <h5>Jeste li sigurni da želite izbrisati ovaj projekat?</h5>
        </Modal.Body>
        <Modal.Footer className="projekti-footer">
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Otkaži
          </Button>
          <Button variant="danger" onClick={removeProject}>
            Da, izbriši
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjektiList;
