import { saveAs } from "file-saver";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAuthHeaders = () => {
  const token = localStorage.getItem("accessToken");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

//API FOR USER PROFILE
export const apiGet = async (endpoint) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    });

    if (!response.ok) throw new Error(`Error: ${response.statusText}`);

    return await response.json();
  } catch (error) {
    console.error("Fetch GET error:", error);
    throw error;
  }
};

export const apiPost = async (endpoint, data) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw { response: { status: response.status, data: errorData } };
    }

    return await response.json();
  } catch (error) {
    console.error("Fetch POST error:", error);

    if (!error.response) {
      throw {
        response: {
          status: 500,
          data: { message_bs: "Neuspješan zahtjev. Pokušajte ponovo." },
        },
      };
    }

    throw error;
  }
};

export const apiPostFormData = async (endpoint, formData) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        ...getAuthHeaders(),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw { response: { status: response.status, data: errorData } };
    }

    return await response.json();
  } catch (error) {
    console.error("Fetch POST FormData error:", error);

    if (!error.response) {
      throw {
        response: {
          status: 500,
          data: { message_bs: "Neuspješan zahtjev. Pokušajte ponovo." },
        },
      };
    }

    throw error;
  }
};

export const apiPutFormData = async (endpoint, formData) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "PUT",
      headers: {
        ...getAuthHeaders(),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw { response: { status: response.status, data: errorData } };
    }

    return await response.json();
  } catch (error) {
    console.error("Fetch PUT FormData error:", error);

    if (!error.response) {
      throw {
        response: {
          status: 500,
          data: { message_bs: "Neuspješan zahtjev. Pokušajte ponovo." },
        },
      };
    }

    throw error;
  }
};

export const apiPut = async (endpoint, data, isFormData = false) => {
  try {
    const headers = isFormData
      ? getAuthHeaders()
      : { "Content-Type": "application/json", ...getAuthHeaders() };

    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "PUT",
      headers,
      body: isFormData ? data : JSON.stringify(data),
    });

    if (!response.ok) throw new Error(`Error: ${response.statusText}`);

    return await response.json();
  } catch (error) {
    console.error("Fetch PUT error:", error);
    throw error;
  }
};

export const apiDelete = async (endpoint) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "DELETE",
      headers: getAuthHeaders(),
    });

    if (!response.ok) throw new Error(`Error: ${response.statusText}`);

    return response.status === 204 ||
      response.headers.get("content-length") === "0"
      ? { success: true }
      : await response.json();
  } catch (error) {
    console.error("Fetch DELETE error:", error);
    throw error;
  }
};

//API FOR SITE
const getLanguagePrefix = () => {
  let language = localStorage.getItem("i18nextLng") || "bs";

  if (language === "en-US") {
    language = "en";
  }

  return language === "en" ? "/en" : "";
};

export const getApiUrl = (endpoint) => {
  const languagePrefix = getLanguagePrefix();
  return `${BASE_URL}${languagePrefix}/${endpoint}`;
};

// Function to fetch news data for main page
export const fetchNovosti = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/mainpage/novosti`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Announcements data for main page
export const fetchObavijesti = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/mainpage/obavijesti`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Knjige data for main page
export const fetchKnjige = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/mainpage/osoblje/knjige`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Naucni radovi data for main page
export const fetchNaucniRadovi = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/mainpage/osoblje/naucni-radovi`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Statements data
export const fetchDekanatIzjave = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/dekanat/izjave`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Dean's List data
export const fetchDekanat = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/dekanat/`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Departments List data
export const fetchOdsjeci = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/odsjeci/`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Departments details data
export const fetchOdsjeciDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/odsjeci/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch News List data
export const fetchAllNovosti = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/novosti/?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch News detail data
export const fetchNovostiDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/novosti/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};
export const fetchAllObavijesti = async (
  page = 1,
  title,
  selectedFilter = "Sve obavijesti"
) => {
  try {
    // Build the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/obavijesti/?page=${page}`;

    if (selectedFilter === "All announcements") {
      selectedFilter = "";
    }

    // Add conditional parameters to the URL
    let url = baseUrl;
    if (title) {
      url += `&title=${encodeURIComponent(title)}`;
    }
    if (selectedFilter && selectedFilter !== "Sve obavijesti") {
      url += `&category=${encodeURIComponent(selectedFilter)}`;
    }

    // Fetch the data
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching obavijesti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Announcements details
export const fetchObavijestiDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/obavijesti/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching obavijesti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Staff List data
export const fetchAllOsoblje = async (page = 1, rank, filterName) => {
  const url = `${BASE_URL}/api/nastavno-osoblje/?page=${page}`;
  const params = [];

  if (rank) {
    params.push(`rank=${encodeURIComponent(rank)}`);
  }

  if (filterName) {
    params.push(`search=${encodeURIComponent(filterName)}`);
  }

  const fullUrl = params.length ? `${url}&${params.join("&")}` : url;

  try {
    const response = await fetch(fullUrl);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching stuff data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

export const fetchProfesoriDetail = async (slug) => {
  try {
    const response = await fetch(`${BASE_URL}/api/nastavno-osoblje/${slug}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};
export const fetchAllDocuments = async (
  page = 1,
  title = "",
  selectedFilter,
  selectedCategory
) => {
  try {
    let url = `${BASE_URL}/api/dokumenti/?page=${page}`;

    let headers = {};

    if (selectedCategory === "Materijali za Vijeća") {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      url = `${BASE_URL}/api/dokumenti/vijeca?page=${page}`;
    }
    if (selectedCategory === "Svi dokumenti") {
      url = `${BASE_URL}/api/dokumenti/?page=${page}`;
    }

    if (selectedFilter === "Propisi") {
      url = `${BASE_URL}/api/dokumenti/propisi?page=${page}`;
      if (selectedCategory !== "Sve kategorije") {
        url += `&category=${encodeURIComponent(selectedCategory)}`;
      }
    } else if (selectedFilter === "Javnost rada fakulteta") {
      if (selectedCategory === "Materijali za Vijeća") {
        url = `${BASE_URL}/api/dokumenti/vijece?page=${page}`;
      } else {
        url = `${BASE_URL}/api/dokumenti/javnost?page=${page}`;
        if (selectedCategory !== "Sve kategorije") {
          url += `&category=${encodeURIComponent(selectedCategory)}`;
        }
      }
    } else if (selectedFilter === "Svi dokumenti") {
      if (selectedCategory !== "Sve kategorije") {
        url += `&doc_type=${encodeURIComponent(selectedCategory)}`;
      }
    }

    if (title) {
      url += `&title=${encodeURIComponent(title)}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: headers, // Add the headers to the fetch request
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching dokumenti data:", error);
    throw error;
  }
};
export const getVijeceFile = async (filename) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {};

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `${BASE_URL}/api/dokumenti/vijece/download/${filename}`;

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      console.error("Error response:", data);
      throw new Error(data.message || "An error occurred while downloading.");
    }

    const blob = await response.blob();

    // Extract file name from response headers or use the passed filename
    const contentDisposition = response.headers.get("Content-Disposition");
    let downloadFileName = filename; // Default to the passed filename

    if (contentDisposition && contentDisposition.includes("filename")) {
      const matches = /filename="(.+)"/.exec(contentDisposition);
      if (matches && matches[1]) {
        downloadFileName = matches[1]; // Use the filename from the header if available
      }
    }

    // Use FileSaver to save the file with the correct name
    saveAs(blob, downloadFileName);

    return true;
  } catch (error) {
    console.error("Error fetching file:", error);
    throw error;
  }
};

// Function to fetch Curiculum detail data
export const fetchCuriculumDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/nastava/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch statistics
export const fetchStatistics = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/fakultet/statistika`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch Gallery
export const fetchGallery = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/fakultet/galerija`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to send contact form
export const sendContactForm = async (messageData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/fakultet/kontaktiraj`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(messageData),
    });

    // Check for 429 Too Many Requests
    if (response.status === 429) {
      const retryAfter = response.headers.get("Retry-After");
      console.warn(
        `Rate limit exceeded. Please retry after ${
          retryAfter ? `${retryAfter} seconds` : "a moment"
        }.`
      );
      throw new Error("Rate limit exceeded. Please try again later.");
    }

    // Check for other non-OK responses
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.message || "Failed to send message.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending contact form:", error);
    throw error;
  }
};

// Function to fetch Curiculum
export const fetchCuriculum = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/nastava/nastavni-planovi`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to fetch all Masinijade
export const fetchAllMasinijade = async (title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/masinijade/`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Masinijada detail data
export const fetchMasnijadaDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/masinijade/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching masinijada data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Konferencije List data
export const fetchAllKonferencije = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/konferencije/?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Konferencije detail data
export const fetchKonferencijeDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/konferencije/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch all Fondovi

export const fetchAllFondovi = async () => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}${getLanguagePrefix()}/api/fondovi/`;
    // Add title to the URL only if it exists
    // const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Fond detail data
export const fetchFondDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/fondovi/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch history

export const fetchFacultyHistory = async () => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}${getLanguagePrefix()}/api/fakultet/historija`;
    // Add title to the URL only if it exists
    // const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch all Studentske Novosti
export const fetchAllStudentskeNovosti = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/studenti/?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&title=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data);
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch News detail data
export const fetchStudentskeNovostiDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/studenti/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data);
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Rezultati ispita
export const fetchAllRezultatiIspita = async (page = 1, filterName) => {
  const url = `${BASE_URL}${getLanguagePrefix()}/api/rezultati-ispita?page=${page}`;
  const params = [];

  if (filterName) {
    params.push(`search=${encodeURIComponent(filterName)}`);
  }

  const fullUrl = params.length ? `${url}&${params.join("&")}` : url;

  try {
    const response = await fetch(fullUrl);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching stuff data:", error);
    throw error;
  }
};

// Function to fetch all Projekti
export const fetchAllProjekti = async () => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}${getLanguagePrefix()}/api/istrazivanje/fakultet/projekti/`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

export const fetchProjektiDetail = async (slug) => {
  try {
    const response = await fetch(
      `${BASE_URL}${getLanguagePrefix()}/api/istrazivanje/fakultet/projekti/${slug}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching masinijada data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Book List data
export const fetchAllKnjige = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/istrazivanje/osoblje/knjige?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&search=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Book List data
export const fetchProfesoriKnjige = async (slug) => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}${getLanguagePrefix()}/api/nastavno-osoblje/${slug}/knjige`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error;
  }
};

// Function to fetch Book List data
export const fetchAllRadovi = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}/api/istrazivanje/osoblje/naucni-radovi?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&search=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};

// Function to fetch Radovi List data
export const fetchProfesoriRadovi = async (slug, limit) => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}/api/nastavno-osoblje/${slug}/naucni-radovi?limit=${limit}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error;
  }
};

// Function to fetch Projekti profesora
export const fetchProfesoriProjekti = async (slug) => {
  try {
    // Construct the base URL
    const url = `${BASE_URL}${getLanguagePrefix()}/api/nastavno-osoblje/${slug}/projekti`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error;
  }
};

export const fetchAllProjektiOsoblje = async (page = 1, title) => {
  try {
    // Construct the base URL
    const baseUrl = `${BASE_URL}${getLanguagePrefix()}/api/istrazivanje/osoblje/projekti?page=${page}`;
    // Add title to the URL only if it exists
    const url = title ? `${baseUrl}&search=${title}` : baseUrl;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the component
  } catch (error) {
    console.error("Error fetching novosti data:", error);
    throw error; // Rethrow error for handling in the component
  }
};
