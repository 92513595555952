import React, { useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getBooks,
  deleteBook,
  getBookById,
} from "../../../services/userProfileService";
import SharedPagination from "../../../components/SharedPagination";
import AddBook from "./AddBook";
import "./KnjigeList.css";

const KnjigeList = () => {
  const [books, setBooks] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); //OVDE POSTAVIS POCETNI STATE, JA SAM STAVIO DA JE 10

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);

  const [searchQuery, setSearchQuery] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [editingBook, setEditingBook] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const data = await getBooks(page, searchQuery);
      setBooks(data.results);
      setCount(data.count);
      if (data.page_size) {
        //OVDE CEMO PRVO PROVJERIT IMAL OVAJ VAULE DA GA NE POSTAVLJAMO BEZE AKO NEMA
        setPageSize(data.page_size); //OVDE GA SPASAVAMO U NOVI STATE
      }
    } catch (error) {
      console.error("Error fetching books:", error);
      toast.error("Greška pri učitavanju knjiga.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, [page, searchQuery]);

  const navigate = useNavigate();

  const handleStaffBooks = () => {
    const slug = localStorage.getItem("slug");
    navigate(`/fakultet/nastavno-osoblje/${slug}`, {
      state: {
        from: "osoblje/knjige",
      },
    });
  };

  const handleAllBooks = () => {
    navigate("/istrazivanje/osoblje/knjige");
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setSearchParams({ page: newPage });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchParams({ page: 1 });
  };

  const handleAddNewBook = () => {
    setShowForm(true);
    setEditingBook(null);
  };

  const handleEditBook = async (id) => {
    try {
      setLoading(true);
      const bookData = await getBookById(id);
      setEditingBook(bookData);
      setShowForm(true);
    } catch (error) {
      toast.error("Greška pri učitavanju knjige.");
    } finally {
      setLoading(false);
    }
  };

  const handleBookUpdated = () => {
    fetchBooks();
    setShowForm(false);
    setEditingBook(null);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedBookId(id);
    setShowDeleteModal(true);
  };

  const removeBook = async () => {
    if (!selectedBookId) return;
    try {
      setShowDeleteModal(false);
      setLoading(true);
      await deleteBook(selectedBookId);
      toast.success("Knjiga uspješno obrisana!");
      fetchBooks();
    } catch (error) {
      toast.error("Greška pri brisanju knjige.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3" style={{ maxHeight: "max-content" }}>
      <div className="mb-3">
        <h2>Knjige</h2>
        {!showForm && (
          <div className="d-flex justify-content-end user-panel-knjige-table-btns">
            <Button
              onClick={() => handleStaffBooks()}
              variant="success"
              className="me-2"
            >
              Pogledaj svoje knjige
            </Button>
            <Button
              onClick={() => handleAllBooks()}
              variant="info"
              className="me-2"
            >
              Pogledaj sve knjige
            </Button>
            <Button onClick={handleAddNewBook} variant="primary">
              Dodaj novu knjigu
            </Button>
          </div>
        )}
      </div>

      {showForm ? (
        <AddBook
          onCancel={() => {
            setShowForm(false);
            setEditingBook(null);
          }}
          existingData={editingBook}
          onBookUpdate={handleBookUpdated}
        />
      ) : (
        <>
          {/* Search Input */}
          <InputGroup className="mb-3 knjige-search">
            <FormControl
              placeholder="Pretraži knjige..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          <div style={{ overflow: "auto" }}>
            <Table bordered hover className="bg-white shadow-sm knjige-table">
              <thead>
                <tr>
                  <th className="th-image">Slika</th>
                  <th className="book-th-title">Naslov</th>
                  <th>Godina</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : books.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Nema dostupnih knjiga.
                    </td>
                  </tr>
                ) : (
                  books.map((book) => (
                    <tr key={book.id}>
                      <td
                        style={{ width: "100px", textAlign: "center" }}
                        className="td-image"
                      >
                        <img
                          src={book.image}
                          alt={book.title}
                          style={{ maxWidth: "80px", maxHeight: "100px" }}
                        />
                      </td>
                      <td className="book-title clamp-text ">{book.title}</td>
                      <td>{book.year_published}</td>
                      <td className="icons">
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="me-2"
                          onClick={() => handleDeleteConfirmation(book.id)}
                        >
                          <FaTrash />
                        </Button>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleEditBook(book.id)}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          <SharedPagination
            currentPage={page}
            totalPages={Math.ceil(count / pageSize)} //OVDE GA DODAJEMO
            onPageChange={handlePageChange}
          />
        </>
      )}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Body className="text-center knjige-modal">
          <h5>Jeste li sigurni da želite izbrisati ovu knjigu?</h5>
        </Modal.Body>
        <Modal.Footer className="knjige-footer">
          <Button variant="danger" onClick={removeBook}>
            Da, izbriši
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Otkaži
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default KnjigeList;
