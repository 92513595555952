import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import NaucniRadoviSlider from "./NaucniRadovi";
import KnjigeSlider from "./Knjige";

const NaucnoIstrazivackiRadComponent = ({ bg }) => {
  const { t } = useTranslation();
  return (
    <section
      className={`${bg} container__wrapper`}
      id="naucnostIstrazivackiRad"
    >
      <div className="container">
        <h1 className="section_title mb-5">{t("researchSection.mainTitle")}</h1>
        <NaucniRadoviSlider />
        <KnjigeSlider />
      </div>
    </section>
  );
};

export default NaucnoIstrazivackiRadComponent;
