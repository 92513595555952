// router.js
import React, { useState } from "react";
import { Route, Routes, useNavigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { getLocalizedPath } from "./helpers/helpers";

import NavbarComponent from "./components/NavigacijaStranice";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import DepartmentDetail from "./pages/Nastava/Departments/OdsjekDetaljno";
import NotFound from "./components/NotFound";
import Spinner from "./components/Spinner";

import Pocetna from "./pages/Pocetna";
import FakultetPage from "./pages/Fakultet";

import NovostiPage from "./pages/Aktuelnosti";
import NewsDetail from "./pages/Aktuelnosti/Novosti/NovostiDetaljno";
import ObavijestiComponent from "./pages/Aktuelnosti/Obavijesti/ObavijestiComponent";
import ObavijestDetail from "./pages/Aktuelnosti/Obavijesti/ObavijestDetail";
import NastavnoOsobljeComponent from "./pages/Fakultet/Nastavno osoblje/NastavnoOsoblje";
import NastavnoOsobljeDetail from "./pages/Fakultet/Nastavno osoblje/NastavnoOsobljeDetail";
import DocumentComponent from "./pages/Fakultet/Dokumenti/Dokumenti";
import CurriculumDetail from "./pages/Nastava/Curiculum/CiklusDetaljno";
import CommingSoon from "./components/CommingSoon";

import { useTranslation } from "react-i18next";
import Kontakt from "./pages/Fakultet/Kontakt/Kontakt";
import StudentskaSluzba from "./pages/Fakultet/Studentska sluzba/StudentskaSluzba";
import BibliotekaComponent from "./pages/Fakultet/Biblioteka/Biblioteka";
import MasinijadaComponent from "./pages/Aktuelnosti/Masinijada/Masinijada";
import MasinijadaDetaljno from "./pages/Aktuelnosti/Masinijada/MasinijadaDetaljno";
import KonferencijeComponent from "./pages/Aktuelnosti/Konferencije/Konferencije";
import KonferencijeDetaljno from "./pages/Aktuelnosti/Konferencije/KonferencijeDetaljno";
import AsocijacijaComponent from "./pages/Studenti/Masinac/Masinac";
import JavneNabavkeComponent from "./pages/Fakultet/Javne Nabavke/javneNabavke";
import FondoviComponent from "./pages/Fakultet/Fondovi/Fondovi";
import FondDetail from "./pages/Fakultet/Fondovi/FondDetaljno";
import HistorijaComponent from "./pages/Fakultet/Historija/Historija";
import StudentskiBlogComponent from "./pages/Studenti/Studenski blog/StudentskiBlog";
import StudentskiBlogDetaljnoComponent from "./pages/Studenti/Studenski blog/StudentskiBlogDetaljno";
import RezultatiIspita from "./pages/Nastava/RezultatiIspita/RezultatiIspita";
import ProjektiComponent from "./pages/Istrazivanja/ProjektiFakulteta/Projekti";
import ProjektiDetail from "./pages/Istrazivanja/ProjektiFakulteta/ProjektiDetaljno";
import UserProfile from "./features/userProfile/UserProfile";
import ProtectedRoute from "./components/ProtectedRoute";
import BooksComponent from "./pages/Istrazivanja/Osoblje/Knjige/Knjige";
import NaucniRadoviComponent from "./pages/Istrazivanja/Osoblje/Naucni radovi/NaucniRadovi";
import ProjektiOsobljeComponent from "./pages/Istrazivanja/Osoblje/Projekti/Projekti";

function LanguageRedirect({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const language = i18n.language;

    if (language === "en" && !location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(`/en${location.pathname}`, { replace: true });
    } else if (language === "bs" && location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(location.pathname.replace(/^\/en/, ""), { replace: true });
    } else {
      setRedirecting(false);
    }
  }, [i18n.language, location.pathname, navigate]);

  if (redirecting) {
    return <Spinner />; // Render a loading spinner until redirection is complete
  }

  return children;
}

function AppRouter() {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const language = i18n.language || "bs"; // Default to Bosnian

    if (language && !initialized) {
      i18n.changeLanguage(language).then(() => setInitialized(true));
    }
  }, [i18n, initialized]);

  if (!initialized) {
    return <Spinner />;
  }

  return (
    <>
      <LanguageRedirect>
        <Routes>
          <Route element={<PublicLayout />}>
            {/* Home page route */}
            <Route path={"/"} element={<Pocetna />} />
            <Route path={"/en"} element={<Pocetna />} />

            {/* Fakultet routes */}
            <Route path={"/fakultet"} element={<FakultetPage />} />
            <Route path={"en/fakultet"} element={<FakultetPage />} />
            <Route
              path={"/fakultet/nastavno-osoblje"}
              element={<NastavnoOsobljeComponent />}
            />
            <Route
              path={"en/fakultet/nastavno-osoblje"}
              element={<NastavnoOsobljeComponent />}
            />
            <Route
              path={"/fakultet/nastavno-osoblje/:slug"}
              element={<NastavnoOsobljeDetail />}
            />
            <Route
              path={"en/fakultet/nastavno-osoblje/:slug"}
              element={<NastavnoOsobljeDetail />}
            />
            <Route
              path={"/fakultet/dokumenti"}
              element={<DocumentComponent />}
            />
            <Route
              path={"en/fakultet/dokumenti"}
              element={<DocumentComponent />}
            />
            <Route
              path={"/istrazivanje/fakultet/projekti"}
              element={<ProjektiComponent />}
            />
            <Route
              path={"en/istrazivanje/fakultet/projekti"}
              element={<ProjektiComponent />}
            />
            <Route
              path={"/istrazivanje/fakultet/projekti/:slug"}
              element={<ProjektiDetail />}
            />
            <Route
              path={"en/istrazivanje/fakultet/projekti/:slug"}
              element={<ProjektiDetail />}
            />

            <Route path={"/aktuelnosti/novosti"} element={<NovostiPage />} />
            <Route path={"en/aktuelnosti/novosti"} element={<NovostiPage />} />
            <Route
              path={"/aktuelnosti/novosti/:slug"}
              element={<NewsDetail />}
            />
            <Route
              path={"en/aktuelnosti/novosti/:slug"}
              element={<NewsDetail />}
            />

            <Route
              path={"/aktuelnosti/obavijesti"}
              element={<ObavijestiComponent />}
            />
            <Route
              path={"en/aktuelnosti/obavijesti"}
              element={<ObavijestiComponent />}
            />
            <Route
              path={"/aktuelnosti/obavijesti/:slug"}
              element={<ObavijestDetail />}
            />
            <Route
              path={"en/aktuelnosti/obavijesti/:slug"}
              element={<ObavijestDetail />}
            />
            <Route
              path={"/aktuelnosti/masinijade"}
              element={<MasinijadaComponent />}
            />
            <Route
              path={"/aktuelnosti/masinijade/:slug"}
              element={<MasinijadaDetaljno />}
            />
            <Route
              path={"en/aktuelnosti/masinijade"}
              element={<MasinijadaComponent />}
            />
            <Route
              path={"en/aktuelnosti/masinijade/:slug"}
              element={<MasinijadaDetaljno />}
            />
            <Route
              path={"/aktuelnosti/konferencije"}
              element={<KonferencijeComponent />}
            />
            <Route
              path={"/aktuelnosti/konferencije/:slug"}
              element={<KonferencijeDetaljno />}
            />

            <Route
              path={"en/aktuelnosti/konferencije"}
              element={<KonferencijeComponent />}
            />
            <Route
              path={"en/aktuelnosti/konferencije/:slug"}
              element={<KonferencijeDetaljno />}
            />
            <Route path={"/fakultet/kontakt"} element={<Kontakt />} />
            <Route path={"en/fakultet/kontakt"} element={<Kontakt />} />

            <Route
              path={"/fakultet/studentska-sluzba"}
              element={<StudentskaSluzba />}
            />
            <Route
              path={"en/fakultet/studentska-sluzba"}
              element={<StudentskaSluzba />}
            />
            <Route
              path={"/fakultet/biblioteka"}
              element={<BibliotekaComponent />}
            />
            <Route
              path={"en/fakultet/biblioteka"}
              element={<BibliotekaComponent />}
            />
            <Route
              path={"/fakultet/javne-nabavke"}
              element={<JavneNabavkeComponent />}
            />
            <Route
              path={"en/fakultet/javne-nabavke"}
              element={<JavneNabavkeComponent />}
            />
            <Route path={"/fakultet/fondovi"} element={<FondoviComponent />} />
            <Route
              path={"en/fakultet/fondovi"}
              element={<FondoviComponent />}
            />

            <Route
              path={"/fakultet/historija"}
              element={<HistorijaComponent />}
            />
            <Route
              path={"en/fakultet/historija"}
              element={<HistorijaComponent />}
            />

            <Route path={"/fakultet/fondovi/:slug"} element={<FondDetail />} />
            <Route
              path={"en/fakultet/fondovi/:slug"}
              element={<FondDetail />}
            />
            <Route
              path={"/studenti/biblioteka"}
              element={<BibliotekaComponent />}
            />
            <Route
              path={"en/studenti/biblioteka"}
              element={<BibliotekaComponent />}
            />

            <Route
              path={"/studenti/vsmf-masinac"}
              element={<AsocijacijaComponent />}
            />
            <Route
              path={"en/studenti/vsmf-masinac"}
              element={<AsocijacijaComponent />}
            />
            <Route
              path={"/aktuelnosti/studenti"}
              element={<StudentskiBlogComponent />}
            />
            <Route
              path={"en/aktuelnosti/studenti"}
              element={<StudentskiBlogComponent />}
            />
            <Route
              path={"/aktuelnosti/studenti/:slug"}
              element={<StudentskiBlogDetaljnoComponent />}
            />
            <Route
              path={"en/aktuelnosti/studenti/:slug"}
              element={<StudentskiBlogDetaljnoComponent />}
            />

            <Route
              path={"/istrazivanje/osoblje/knjige"}
              element={<BooksComponent />}
            />
            <Route
              path={"en/istrazivanje/osoblje/knjige"}
              element={<BooksComponent />}
            />
            <Route
              path={"/istrazivanje/osoblje/naucni-radovi"}
              element={<NaucniRadoviComponent />}
            />
            <Route
              path={"en/istrazivanje/osoblje/naucni-radovi"}
              element={<NaucniRadoviComponent />}
            />
            <Route
              path={"/istrazivanje/osoblje/projekti"}
              element={<ProjektiOsobljeComponent />}
            />
            <Route
              path={"en/istrazivanje/osoblje/projekti"}
              element={<ProjektiOsobljeComponent />}
            />

            <Route path={"/coomingsoon"} element={<CommingSoon />} />
            <Route path={"en/coomingsoon"} element={<CommingSoon />} />

            <Route path="*" element={<NotFound />} />
            <Route path={"/odsjeci/:slug"} element={<DepartmentDetail />} />
            <Route path={"en/odsjeci/:slug"} element={<DepartmentDetail />} />
            <Route path={"/nastava/:slug"} element={<CurriculumDetail />} />
            <Route path={"en/nastava/:slug"} element={<CurriculumDetail />} />
            <Route
              path={"/nastava/rezultati-ispita"}
              element={<RezultatiIspita />}
            />
            <Route
              path={"en/nastava/rezultati-ispita"}
              element={<RezultatiIspita />}
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="en/profile" element={<UserProfile />} />
          </Route>
        </Routes>
      </LanguageRedirect>
    </>
  );
}

const PublicLayout = () => (
  <>
    <NavbarComponent />
    <ScrollToTop />
    <Outlet />
    <Footer />
  </>
);

export default AppRouter;
