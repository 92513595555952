import React from "react";
import styles from "./PrvaSekcija.module.css"; // Import the CSS module
import { useTranslation } from "react-i18next";

const AdvantageSection = ({ bg }) => {
  const { t } = useTranslation();

  const cardsData = [
    {
      titleKey: "facultyAdvantagesSection.advantages.program.title",
      textKey: "facultyAdvantagesSection.advantages.program.text",
    },
    {
      titleKey: "facultyAdvantagesSection.advantages.education.title",
      textKey: "facultyAdvantagesSection.advantages.education.text",
    },
    {
      titleKey: "facultyAdvantagesSection.advantages.resources.title",
      textKey: "facultyAdvantagesSection.advantages.resources.text",
    },
    {
      titleKey: "facultyAdvantagesSection.advantages.careerSupport.title",
      textKey: "facultyAdvantagesSection.advantages.careerSupport.text",
    },
    {
      titleKey: "facultyAdvantagesSection.advantages.industry.title",
      textKey: "facultyAdvantagesSection.advantages.industry.text",
    },
    {
      titleKey: "facultyAdvantagesSection.advantages.research.title",
      textKey: "facultyAdvantagesSection.advantages.research.text",
    },
  ];

  return (
    <section className={`${bg} container__wrapper`} id="onama">
      <div className="container">
        <h2 className="section_title">{t("facultyAdvantagesSection.title")}</h2>
        <p className="section_subtitle col-lg-10">
          {t("facultyAdvantagesSection.subtitle")}
        </p>

        <div className="row mt-5">
          {cardsData.map((card, index) => (
            <div
              className={`col-lg-4 col-md-6 col-sm-12 ${styles.cardColumn}`}
              key={index}
            >
              <div className={styles.cardCustom}>
                <h3 className="card__title">{t(card.titleKey)}</h3>
                <p className="card__subtitle">{t(card.textKey)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;
