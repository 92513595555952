import React from "react";
import "../../styles/global.css";
import CycleComponent from "../../components/CiklusiKomponenta";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const FacultyCycles = ({ bg }) => {
  const { t } = useTranslation(); // Use useTranslation hook to get translation function

  return (
    <div className={`${bg} container__wrapper`} id="ciklusi">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-3">
          <div>
            <h2 className="section_title">
              {t("facultyCycles.title")} {/* Use translation here */}
            </h2>
            <p className="section_subtitle">
              {t("facultyCycles.subtitle")} {/* Use translation here */}
            </p>
          </div>
        </div>
        <CycleComponent />
      </div>
    </div>
  );
};

export default FacultyCycles;
