import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProfesoriProjekti } from "../../../../services/apiService";
import styles from "./ProjektiList.module.css";

const ProjektiTab = ({ slug }) => {
  const { t } = useTranslation();
  const [projekti, setProjekti] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const getProjekti = async () => {
      setLoading(true);

      try {
        const data = await fetchProfesoriProjekti(slug);

        setProjekti(data.results);
      } catch (err) {
        console.error("Error fetching projects:", err);
      } finally {
        setLoading(false);
      }
    };

    getProjekti();
  }, [slug]);

  if (loading && projekti.length === 0) {
    return (
      <div className="text-center my-4">
        <Spinner animation="border" />
      </div>
    );
  }

  if (projekti.length === 0) {
    return <p>{t("professorDetail.notAvailable")}</p>;
  }

  const handleOpenModal = (projekt) => {
    setSelectedProject(projekt);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const renderModalContent = () => {
    if (!selectedProject) return null;

    const {
      title,
      manager,
      members,
      description,
      url,
      funder,
      hasMembers,
      year_started,
      project_finished,
    } = selectedProject;

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{t("professorDetail.funder")}:</strong> {funder || "-"}
          </p>
          <p>
            <strong>{t("professorDetail.voditeljProjekta")}:</strong>{" "}
            {manager ? (
              manager.slug ? (
                <Link
                  to={manager.slug}
                  className={styles.links}
                  onClick={handleCloseModal}
                >
                  {manager.name}
                </Link>
              ) : (
                manager.name
              )
            ) : (
              "-"
            )}
          </p>
          <p>
            <strong>{t("professorDetail.clanoviProjekta")}:</strong>{" "}
            {members.map((m, i) => {
              const isLast = i === members.length - 1;
              const separator = !isLast ? (
                <span dangerouslySetInnerHTML={{ __html: " &bull; " }} />
              ) : null;
              return (
                <span key={i}>
                  {m.slug ? (
                    <Link
                      to={m.slug}
                      className={styles.links}
                      onClick={handleCloseModal}
                    >
                      {m.name}
                    </Link>
                  ) : (
                    m.name
                  )}
                  {separator}
                </span>
              );
            })}
          </p>
          <p>
            <strong>{t("professorDetail.projektiDescription")}:</strong>{" "}
            {description || "-"}
          </p>

          <p>
            <strong>{t("professorDetail.started")}:</strong> {year_started}
          </p>
          <p>
            <strong>{t("professorDetail.finished")}:</strong> {project_finished}
          </p>
          <p>
            <strong>{t("professorDetail.projectUrl")}:</strong>{" "}
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            ) : (
              "-"
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("professorDetail.close")}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <div className={styles.radoviContainer}>
      <Row>
        {projekti.map((project, idx) => (
          <Col key={idx} xs={12} className="mb-3">
            <div className={styles.projectCard}>
              <h6 className={styles.projectTitle}>{project.title}</h6>

              <p className={styles.yearFunderRow}>
                {project.year_started} - {project.project_finished}
                <br />
                <span className="text-muted">{project.funder}</span>
              </p>

              <button
                className={styles.readMoreButton}
                onClick={() => handleOpenModal(project)}
              >
                {t("professorDetail.readMore")}
              </button>
            </div>
          </Col>
        ))}
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default ProjektiTab;
