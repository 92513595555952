import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import ButtonComponent from "../../components/ButtonComponent";
import { fetchOdsjeci } from "../../services/apiService";
import styles from "./Odsjeci.module.css";
import { useTranslation } from "react-i18next";

const FacultyDepartments = ({ bg }) => {
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchOdsjeci();
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, [i18n.language]);

  const handleCardClick = (slug) => {
    // Navigate to the department detail page
    navigate(`/odsjeci/${slug}`);
  };

  return (
    <div className={`${bg} container__wrapper`} id="odsjeci">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-2">
          <div className="mb-3">
            <h3 className="section_title">{t("facultyDepartments.title")}</h3>
            <p className="section_subtitle col-lg-10">
              {t("facultyDepartments.subtitle")}
            </p>
          </div>
        </div>
        <div className="row g-4">
          {departments.map((dept) => (
            <div
              key={dept.slug}
              className="col-lg-4 col-md-6 col-12"
              onClick={() => handleCardClick(dept.slug)} // Handle card click
            >
              <div className={`${styles.card} `} style={{ cursor: "pointer" }}>
                <div className={`${styles.cardIcon}`}>
                  <img
                    src={dept.icon}
                    alt={`${dept.name} icon`}
                    className={styles.cardImage} // Add the image styling here
                  />
                </div>

                <h4 className={`${styles.cardTitle}`}>{dept.name}</h4>

                <ButtonComponent
                  label={t("facultyDepartments.readMore")}
                  color="#000"
                  backgroundColor="#9FDAF8"
                  className={styles.cardButton} // Apply button class
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleCardClick(dept.slug); // Handle button click
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FacultyDepartments;
