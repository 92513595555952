import React from "react";
import "../../../styles/global.css";
import style from "../../../components/DekanatIzjaveShared.module.css";
import styleCard from "./IzjavePolaznika.module.css";

const IzjavePolaznika = ({ izjave }) => {
  return (
    <div
      className={`d-flex flex-wrap container justify-content-start ${styleCard.izjaveContainer}`}
    >
      {izjave.map((item, index) => (
        <div
          key={index}
          className={`${style.statementsWrapper} d-flex justify-content-center ${styleCard.izjavaItem}`}
        >
          <div className="d-flex flex-column justify-content-between align-items-center">
            <p className={`${style.statementsText} mb-5 `}>
              <i>"{item.statement}"</i>
            </p>

            <div className="d-flex border-top pt-2 w-100">
              <img
                src={item.image}
                alt={item.full_name}
                className={style.profileImage}
              />
              <div>
                <h5 className="fs-5 mb-1 text-black">{item.full_name}</h5>
                <p className={style.positionSubtitle}>{item.position}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IzjavePolaznika;
